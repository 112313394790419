import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'

export const SidebarFilterContainer = styled('div')<{
    topMarginOverride?: number
}>(({ topMarginOverride }) => ({
    display: 'flex',
    gap: calcRem(40),
    flexDirection: 'column',
    marginTop: topMarginOverride,

    [breakpoint('lg')]: {
        gap: calcRem(32),
    },
}))
