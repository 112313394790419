import {
    AllEvents,
    queries,
    ConfigurationContext,
    ElectionDefinition,
} from '@news-mono/web-common'
import React, { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import { ElectionText } from '../components'
import { StyledTitleContainer } from '../FullElectorateWidget/FullElectorateWidget.styled'
import { CustomPageSharing } from '../../../buttons/SharingCustomPage/CustomPageSharing'
import styled from '@emotion/styled'
import { breakpoint, calcRem } from '../../../__styling'

export interface TNElectorateHeaderProps {
    electionDefinition: ElectionDefinition
    onEvent: (event: AllEvents) => void
}

export const TNElectorateHeader = ({
    onEvent,
    electionDefinition,
}: TNElectorateHeaderProps): JSX.Element => {
    const config = useContext(ConfigurationContext)
    const result = useQuery(
        queries['election-api'].definition({
            initialDefinition: electionDefinition,
            electionAPI: config.electionApi,
            caller: config.apiCallerHeader,
        }),
    )

    return (
        <StyledTitleContainer>
            <StyledElectorateTitle>
                {'Electorate Results'}
            </StyledElectorateTitle>
            <ElectionText>
                {'Live tracking of electorates as they are called.'}
            </ElectionText>
            <CustomPageSharing
                text={`${
                    result.data &&
                    result.data.electionData.config?.electionTitle
                } electorate results | The Nightly`}
                shareOptions={[
                    'facebook',
                    'linkedin',
                    'twitter',
                    'email_tn',
                    'clipboard',
                ]}
                loading={false}
                onEvent={onEvent}
            />
        </StyledTitleContainer>
    )
}

export const StyledElectorateTitle = styled('h1')({
    fontSize: calcRem(40),
    fontWeight: 700,
    lineHeight: calcRem(48),
    letterSpacing: '-4%',
    margin: 0,
    [breakpoint('lg')]: {
        fontSize: calcRem(51),
        lineHeight: calcRem(56),
    },
})
