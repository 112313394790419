import { ElectionDefinition, GetRouteAdTargeting } from '@news-mono/web-common'
import { layout } from '../../App.routing'
import { calcRem, metrics } from '@news-mono/component-library'
import { wrapWithElectionContextNested } from '../elections/routes'

export const federalElection2025Segment = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
) => {
    return wrapWithElectionContextNested([
        layout.nestedComposition({
            type: 'box',
            props: {
                containerWidth: metrics.thenightly.siteMetrics.mainContentWidth,
                fullWidth: true,
                flex: {
                    default: {
                        flexDirection: 'column',
                    },
                    sm: {},
                },
            },
            contentAreas: {
                main: [
                    layout.component({
                        type: 'federal-election-2025-logo',
                        props: {},
                    }),
                    layout.nestedComposition({
                        type: 'box',
                        props: {
                            containerWidth:
                                metrics.thenightly.siteMetrics.mainContentWidth,
                            fullWidth: true,
                            flex: {
                                default: {
                                    flexDirection: 'column',
                                    gap: calcRem(48),
                                },
                                sm: {
                                    gap: calcRem(32),
                                },
                            },
                        },
                        contentAreas: {
                            main: [
                                layout.nestedComposition({
                                    type: 'box',
                                    props: {
                                        horizontalSpacing: 'outerMargin',
                                        horizontalSpacingBreakpoint: 'lg',
                                    },
                                    feature:
                                        'federal-election-homepage-the-race',
                                    contentAreas: {
                                        main: [
                                            layout.component({
                                                type: 'election-the-race-widget',
                                                props: {
                                                    electionDefinition,
                                                    headingLevel: 'h3',
                                                },
                                            }),
                                        ],
                                    },
                                }),
                                layout.nestedComposition({
                                    type: 'luna',
                                    props: {
                                        asideSide: 'right',
                                        hasAfterAside: false,
                                        hasBeforeAside: true,
                                        dividerConfig: {
                                            column: {
                                                hasBeforeAsideAndAsideDivider:
                                                    false,
                                            },
                                        },
                                    },
                                    contentAreas: {
                                        beforeAside: [
                                            layout.component({
                                                type: 'card',
                                                props: {
                                                    kind: 'article-card',
                                                    dataDefinitionArgs: {
                                                        type: 'curation',
                                                        name: 'federal-election-2025',
                                                        offset: 0,
                                                        pageSize: 1,
                                                    },
                                                    adTargeting: getAdTargeting(
                                                        'home',
                                                        'default',
                                                        'home',
                                                    ),
                                                    imageFetchPriority: 'high',
                                                    disableImageLazyLoad: true,
                                                },
                                            }),
                                        ],
                                        afterAside: [],
                                        aside: [
                                            layout.nestedComposition({
                                                type: 'box',
                                                props: {
                                                    horizontalSpacing:
                                                        'outerMargin',
                                                    horizontalSpacingBreakpoint:
                                                        'sm',
                                                },
                                                contentAreas: {
                                                    main: [
                                                        layout.component({
                                                            type: 'juliet',
                                                            props: {
                                                                cardType: {
                                                                    type: 'landscape',
                                                                    format: 'landscape-common',
                                                                },
                                                                imageWidths: {
                                                                    mobile: '80px',
                                                                    tablet: '80px',
                                                                    desktop:
                                                                        '96px',
                                                                    fallbackWidth: 96,
                                                                    mode: 'fixed',
                                                                },
                                                                verticalSpacing:
                                                                    'md',
                                                                removeHorizontalGutters:
                                                                    true,
                                                                removeTopListPadding:
                                                                    true,
                                                                hideByline:
                                                                    false,
                                                                topicLevel:
                                                                    'parent',
                                                                hideLastDivider:
                                                                    true,
                                                                condensedInfo:
                                                                    true,
                                                                dataDefinitionArgs:
                                                                    {
                                                                        type: 'curation',
                                                                        name: 'federal-election-2025',
                                                                        offset: 1,
                                                                        pageSize: 6,
                                                                    },
                                                            },
                                                        }),
                                                    ],
                                                },
                                            }),
                                        ],
                                    },
                                }),
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        adType: 'inline',
                                        noticePosition: 'above-center',
                                        slot: {
                                            id: '0-election-1',
                                            size: 'leaderboardAbove768MrecBelow',
                                        },
                                        padding: [0, 0, 0, 0],
                                        lazyLoadingDistance: 0,
                                    },
                                }),
                                layout.nestedComposition({
                                    type: 'box',
                                    props: {
                                        horizontalSpacing: 'outerMargin',
                                        horizontalSpacingBreakpoint: 'lg',
                                    },
                                    feature:
                                        'federal-election-homepage-the-seats',
                                    contentAreas: {
                                        main: [
                                            layout.component({
                                                type: 'election-seat-widget',
                                                props: {
                                                    electoratePageUrl: `/politics/${electionDefinition.electionId}/electorates`,
                                                    electionDefinition,
                                                    headingLevel: 'h3',
                                                },
                                            }),
                                        ],
                                    },
                                }),
                                layout.component({
                                    type: 'ad-unit',
                                    props: {
                                        adType: 'inline',
                                        noticePosition: 'above-center',
                                        slot: {
                                            id: '0-election-2',
                                            size: 'leaderboardAbove768MrecBelow',
                                        },
                                        padding: [0, 0, 0, 0],
                                        lazyLoadingDistance: 0,
                                    },
                                    feature:
                                        'federal-election-homepage-the-seats',
                                }),
                                layout.nestedComposition({
                                    type: 'box',
                                    props: {
                                        horizontalSpacing: 'outerMargin',
                                        horizontalSpacingBreakpoint: 'lg',
                                    },
                                    feature:
                                        'federal-election-homepage-the-seats',
                                    contentAreas: {
                                        main: [
                                            layout.component({
                                                type: 'election-map-overview-widget',
                                                props: {
                                                    titleText: 'Map overview',
                                                    electionDefinition,
                                                },
                                            }),
                                        ],
                                    },
                                }),
                            ],
                        },
                    }),
                ],
            },
        }),
    ])
}
