import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { TheNightlySection } from '@news-mono/common'
import {
    AppProps,
    AuthenticationHandler,
    PageResolver,
    ProvideRouteResolvedDataToTheme,
    regionalCanonicalMeta,
} from '@news-mono/component-library'
import {
    errorLocation,
    getRedirectRouteInfo,
    getSectionMetaInfo,
    matchedErrorRoutePages,
    Product,
    registerPageRoutes,
    RenderTargetContext,
    StaticRoutes,
    Features,
    ElectionDefinition,
    LoadedElectionDefinition,
} from '@news-mono/web-common'
import {
    isArticleLikePublication,
    TopicV4DTO,
} from '@west-australian-newspapers/publication-types'
import { PageProps } from 'page-lifecycle-provider'
import queryString from 'query-string'
import React from 'react'
import { useLocation } from 'react-router'
import './App.css'
import { layout } from './App.routing'
import { staticRoutes } from './routes'
import { createCookieSettingsPage } from './routes/cookie-consent'
import { createDiagPage } from './routes/diag-routes'
import {
    matchedSomethingWentWrongRoute,
    notFoundRoute,
    staticSomethingWentWrongRoute,
} from './routes/errors'
import { getProfilePageRouteInfo } from './routes/profile/get-profile-page-route-info'
import { createArticleRoute } from './routes/publication/kind/article'
import { createFeatureRoute } from './routes/publication/kind/feature'
import { createGalleryRoute } from './routes/publication/kind/gallery'
import { getSearchPageRouteInfo } from './routes/search/get-search-page-route-info'
import { getTopicPageRouteInfo } from './routes/topic/get-topic-page-route-info'
import { Site, siteAds } from './Site'
import { createSpecificEditionPage } from './routes/ePaper/specific-edition'
import { createSavedPublicationPage } from './routes/saved-publications/get-saved-articles'
import { initializeDatadog } from './datadog'
import { createShorthandRoute } from './routes/publication/kind/shorthand'
import {
    createSpecificElectionEmbedPage,
    createSpecificElectionPage,
    createSpecificPartyTotalsPage,
    createSpecificElectoratesPage,
} from './routes/elections/pages/createRoutes'
import { getLoadedElectionDefinition } from './routes/elections/routes'
import { createTheNightlyOnLandingPage } from './routes/thenightly-on/createOnLandingPage'
import {
    EditionConfig,
    isEditionsConfigKey,
    onEditionsConfig,
} from './routes/thenightly-on/onEditionsConfig'
import { createTheNightlyOnEditionPage } from './routes/thenightly-on/getOnEditionsRoute'

export function initRouting() {
    registerPageRoutes<TheNightlySection>({
        staticRoutes,
        matchRoutes: {
            '/newsletter-redirect': () => {
                return {
                    kind: 'redirect',
                    redirectTo: {
                        targetUrl:
                            'https://emails.thewest.com.au/subscribe/index.html',
                    },
                }
            },
            // TODO Cleanup the double function call
            '/search$': (services) =>
                getSearchPageRouteInfo(
                    queryString.parse(services.location.search),
                )(services),
            '/saved-articles': (services) =>
                createSavedPublicationPage(
                    queryString.parse(services.location.search),
                )(services),
            '/diag$': ({ config, resolution }) => {
                const section =
                    resolution.type === 'match' && resolution.section
                        ? resolution.section
                        : 'default'
                return createDiagPage(config, section as TheNightlySection)
            },
            '/cookie-settings$': ({ resolution, store }) => {
                const section =
                    resolution.type === 'match' && resolution.section
                        ? resolution.section
                        : 'default'

                return createCookieSettingsPage(
                    store,
                    section as TheNightlySection,
                )
            },
            // The following are required for regional error page matching if you want to explicitly view a regional something went wrong page
            [`${errorLocation}$`]: ({ config, getAdTargeting, resolution }) =>
                matchedSomethingWentWrongRoute(
                    config,
                    getAdTargeting,
                    resolution,
                ),
            ...(matchedErrorRoutePages as StaticRoutes<TheNightlySection>),
        },
        serverRoutes: {
            editions: ({ config, getAdTargeting, resolution }) => {
                return createSpecificEditionPage(
                    config,
                    getAdTargeting,
                    'default',
                    resolution.resolution.edition,
                )
            },
            elections: ({
                resolution,
                getAdTargeting,
                store,
                location,
                config,
            }) => {
                const electionId = resolution.resolution.electionId
                const electionDefinition = getLoadedElectionDefinition(
                    electionId,
                    resolution.resolution.electionData,
                    resolution.resolution.meta,
                )

                switch (resolution.resolution.routeType) {
                    case 'embed-page':
                        return createSpecificElectionEmbedPage(
                            electionDefinition,
                            getAdTargeting,
                            store,
                        )
                    case 'party-totals-page':
                        return createSpecificPartyTotalsPage(
                            electionDefinition,
                            getAdTargeting,
                        )
                    case 'electorates-page':
                        return createSpecificElectoratesPage(
                            electionDefinition,
                            getAdTargeting,
                            store,
                        )
                    case 'landing-page':
                    default:
                        return createSpecificElectionPage(
                            resolution,
                            electionDefinition,
                            getAdTargeting,
                            store,
                            location,
                            config,
                        )
                }
            },
            profile: ({
                config,
                getAdTargeting,
                resolution,
                location,
                store,
            }) => {
                return getProfilePageRouteInfo({
                    profile: resolution.resolution.profile,
                    section: resolution.resolution.section as TheNightlySection,
                    config,
                    getAdTargeting,
                    location,
                    store,
                })
            },
            redirect: ({ resolution }) => {
                return getRedirectRouteInfo(
                    resolution.resolution.redirectTo.targetUrl,
                )
            },
            topic: ({
                resolution,
                getAdTargeting,
                location,
                config,
                store,
            }) => {
                const toggles = store.getState().toggles
                const featureState = toggles && toFeatureState(toggles)

                const section = resolution.resolution
                    .section as TheNightlySection

                //createTheNightlyOnLeadershipPage
                const topic: TopicV4DTO = resolution.resolution.topic
                const topicId = topic.id
                const isEditionPage = isEditionsConfigKey(topicId)
                let isEditionToggleEnabled = false
                let editionsConfig: EditionConfig | undefined

                if (topicId === 'on') {
                    if (
                        isFeatureEnabled<Features>(
                            featureState,
                            'the-nightly-on-tomorrow-route', //@todo: clean up use of feature toggle once tomorrow edition is launched
                            false,
                        )
                    ) {
                        return createTheNightlyOnLandingPage({
                            baseUrl: config.publicUrl,
                            getAdTargeting,
                            location,
                            section,
                            topic: resolution.resolution.topic,
                        })
                    }
                    return null //@todo: remove this once tomorrow edition is launched
                }

                if (isEditionPage) {
                    editionsConfig = onEditionsConfig[topicId]

                    if (editionsConfig) {
                        const featureToggle =
                            editionsConfig.featureToggle as Features
                        // Apply the feature toggle value
                        isEditionToggleEnabled = isFeatureEnabled<Features>(
                            featureState,
                            featureToggle,
                            false,
                        )
                    }
                }

                if (isEditionToggleEnabled && isEditionPage && editionsConfig) {
                    return createTheNightlyOnEditionPage({
                        baseUrl: config.publicUrl,
                        getAdTargeting,
                        location,
                        section,
                        topic: topic,
                        ...editionsConfig,
                    })
                } else {
                    return getTopicPageRouteInfo({
                        publicationKind: resolution.resolution.publicationKind,
                        topic: resolution.resolution.topic,
                        section,
                        getAdTargeting,
                        classification: resolution.resolution.classification,
                        location,
                        baseUrl: config.publicUrl,
                        toggles,
                    })
                }
            },

            publication: ({
                resolution,
                location,
                config,
                store,
                getAdTargeting,
                renderTarget,
            }) => {
                const toggles = store.getState().toggles
                const regionalMeta = isFeatureEnabled(
                    toFeatureState(toggles),
                    'regionals',
                    false,
                )
                    ? regionalCanonicalMeta(
                          config,
                          store.getState().meta.sectionMeta,
                          resolution.resolution.publication,
                      )
                    : undefined
                const sectionMeta = getSectionMetaInfo(
                    store.getState().meta.sectionMeta,
                    resolution.resolution.section as TheNightlySection,
                )
                if (
                    resolution.resolution.publication.kind === 'redirect' &&
                    resolution.resolution.publication.redirectUrl
                ) {
                    return getRedirectRouteInfo(
                        resolution.resolution.publication.redirectUrl,
                    )
                }
                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    const shouldResolveFeatureRoute = isFeatureEnabled(
                        toFeatureState(toggles),
                        'immersive-story-article-template',
                    )

                    const shouldResolveShorthandRoute = isFeatureEnabled(
                        toFeatureState(toggles),
                        'shorthand-article-enabled',
                    )

                    if (
                        resolution.resolution.publication.layoutView ===
                            'feature' &&
                        shouldResolveFeatureRoute
                    ) {
                        return createFeatureRoute({
                            config,
                            article: resolution.resolution.publication,
                            section: resolution.resolution
                                .section as TheNightlySection,
                            getAdTargeting,
                            sectionMeta,
                            pageMeta: regionalMeta,
                            toggles,
                        })
                    }

                    if (
                        resolution.resolution.publication.layoutView ===
                            'shorthand' &&
                        shouldResolveShorthandRoute
                    ) {
                        return createShorthandRoute({
                            config,
                            article: resolution.resolution.publication,
                            section: resolution.resolution
                                .section as TheNightlySection,
                            getAdTargeting,
                            sectionMeta,
                            pageMeta: regionalMeta,
                            toggles,
                        })
                    }
                    return createArticleRoute({
                        config,
                        article: resolution.resolution.publication,
                        section: resolution.resolution
                            .section as TheNightlySection,
                        location,
                        getAdTargeting,
                        pageMeta: regionalMeta,
                        renderTarget,
                        toggles,
                    })
                }
                if (resolution.resolution.publication.kind === 'gallery') {
                    return createGalleryRoute({
                        gallery: resolution.resolution.publication,
                        numberGalleryItems:
                            resolution.resolution.publication.imageCount,
                        section: resolution.resolution
                            .section as TheNightlySection,
                        getAdTargeting,
                        pageMeta: regionalMeta,
                        toggles,
                    })
                }
                return null
            },
            preview: ({
                resolution,
                location,
                config,
                getAdTargeting,
                renderTarget,
            }) => {
                if (
                    isArticleLikePublication(resolution.resolution.publication)
                ) {
                    return createArticleRoute({
                        config,
                        article: resolution.resolution.publication,
                        section: resolution.resolution
                            .section as TheNightlySection,
                        location,
                        getAdTargeting,
                        renderTarget,
                    })
                }
                if (resolution.resolution.publication.kind === 'gallery') {
                    return createGalleryRoute({
                        gallery: resolution.resolution.publication,
                        numberGalleryItems:
                            resolution.resolution.publication.imageCount,
                        section: resolution.resolution
                            .section as TheNightlySection,
                        getAdTargeting,
                    })
                }

                return null
            },
            'video-series': () => {
                // TODO DPT-1990 implement video series landing page
                return null
            },
            'video-series-episode': () => {
                // TODO DPT-1991 implement video series episode page
                return null
            },
            video: () => {
                return null
            },
            'video-topic': () => {
                return null
            },
            'match-centre': () => {
                return null
            },
            'afl-match-centre': () => {
                return null
            },
        },
        errorRouteInfo: staticSomethingWentWrongRoute,
        notFoundRouteInfo: notFoundRoute,
    })
}

initRouting()
export const App: React.FC<AppProps> = ({
    onEvent,
    hostname,
    protocol,
    services,
    gptApi,
}) => {
    initializeDatadog(services)

    const location = useLocation()
    const { renderTarget } = React.useContext(RenderTargetContext)
    return (
        <PageResolver
            layout={layout}
            hostname={hostname}
            protocol={protocol}
            location={location}
            onEvent={onEvent}
            siteAds={siteAds}
            services={services}
            renderTarget={renderTarget}
            gptApi={gptApi}
            renderPage={(
                pageContents,
                ads,
                pageType,
                section,
                additionalPageProperties,
            ) => {
                const resolvedSection = section as TheNightlySection
                return (
                    <ProvideRouteResolvedDataToTheme
                        product={Product.TheNightly}
                        section={resolvedSection}
                    >
                        {/**
                         * These page properties, are used to represent the state of the
                         * design of the current iteration of the website, and should stay for data integrity
                         * until such a point in time arises where the margins or headline style is altered
                         */}
                        <PageProps
                            pageProperties={{
                                marginSize: 'smaller-margins',
                                headlineStyle: 'serif-headlines',
                            }}
                        />
                        <AuthenticationHandler
                            log={services.log}
                            config={services.config}
                            onEvent={onEvent}
                        />
                        <Site
                            pageType={pageType}
                            section={resolvedSection}
                            additionalPageProperties={additionalPageProperties}
                            config={services.config}
                            onEvent={onEvent}
                            pageContents={pageContents}
                            location={location}
                            ads={ads}
                            hostname={hostname || ''}
                        />
                    </ProvideRouteResolvedDataToTheme>
                )
            }}
        />
    )
}
