import { TheNightlySection } from '@news-mono/common'
import {
    breakpoint,
    calcRem,
    IconChevronUpTN,
    metrics,
    sectionThemeOverrider,
} from '@news-mono/component-library'
import {
    getSocialImageMeta,
    PageType,
    TogglesReduxState,
    GetRouteAdTargeting,
    LoadedElectionDefinition,
    useFeature,
    Features,
    isElectionFeatureEnabled,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { sectionNavItems } from '../../../navigation'
import React from 'react'
import { parsePageParam } from '../../helpers'
import queryString from 'query-string'
import H from 'history'
import { wrappingContexts } from '../../topic/get-topic-page-route-info'
import { getYear } from 'date-fns'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { latestOnEdition } from '../../thenightly-on/onEditionsConfig'
import { wrapWithElectionContext } from '../routes'

type CreateElectionPageArgs = {
    electionDefinition: LoadedElectionDefinition
    getAdTargeting: GetRouteAdTargeting
    toggles: TogglesReduxState
    location: H.Location<any>
}

const MAIN_CONTENT_ID = 'main-content'

export const createElectionLandingPage = ({
    location,
    getAdTargeting,
    electionDefinition,
    toggles,
}: CreateElectionPageArgs): PageType<TheNightlySection> => {
    const isTheRaceEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-the-race',
    )
    const isTheSeatsEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-the-seats',
    )
    const isMapEnabled = isElectionFeatureEnabled(
        toggles,
        electionDefinition.electionId,
        'election-landing-page-map',
    )

    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'
    const electionYear = getYear(
        new Date(electionDefinition.electionData?.config?.electionDate ?? ''),
    )
    const electionState = electionDefinition.electionData?.config?.state

    const meta = electionDefinition.meta

    let defaultTitle

    if (isFederal) {
        defaultTitle = `${electionYear} Federal Election`
    } else {
        defaultTitle = `${electionYear} ${electionState} Election`
    }

    const seoTitle =
        meta.topic?.seoTitle ?? meta.updates?.seoTitle ?? defaultTitle
    const seoDescription =
        meta.topic?.seoDescription ?? meta.updates?.seoDescription

    const section = 'politics'
    const query = queryString.parse(location.search)
    const page = parsePageParam(query.page)

    const isLatestNightlyOnEnabled = isFeatureEnabled(
        toFeatureState(toggles),
        latestOnEdition.featureToggle as Features,
    )

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: section,
        hideHeading: true,
        adTargeting: getAdTargeting('home', section, seoTitle),
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}`,
                      },
                  ]
                : undefined,
            meta: meta.updates?.socialImage
                ? getSocialImageMeta(meta.updates.socialImage, '2400', '1260')
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        additionalPageProperties: {
            hasTopGutter: false,
        },
        compositions: wrapWithElectionContext(
            [
                layout.nestedComposition({
                    type: 'scheme-overrider',
                    props: {
                        override: sectionThemeOverrider('thenightly', section),
                    },
                    contentAreas: {
                        children: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    containerWidth:
                                        metrics.thenightly.siteMetrics
                                            .mainContentWidth,
                                    fullWidth: true,
                                    flex: {
                                        default: {
                                            flexDirection: 'column',
                                            gap: calcRem(48),
                                            paddingBottom: calcRem(80),
                                            [breakpoint('sm')]: {
                                                gap: calcRem(80),
                                                paddingBottom: 0,
                                            },
                                        },
                                        sm: {},
                                    },
                                },
                                contentAreas: {
                                    main: [
                                        layout.component({
                                            type: 'election-navigation',
                                            props: {
                                                electionDefinition,
                                                activePage: 'updates',
                                                headingLevel: 'h2',
                                            },
                                        }),
                                        layout.nestedComposition({
                                            type: 'luna',
                                            props: {
                                                asideSide: 'right',
                                                hasAfterAside: false,
                                                hasBeforeAside: true,
                                                dividerConfig: {
                                                    column: {
                                                        hasBeforeAsideAndAsideDivider:
                                                            false,
                                                    },
                                                },
                                                heading: 'Updates',
                                            },
                                            contentAreas: {
                                                beforeAside: [
                                                    layout.component({
                                                        type: 'card',
                                                        props: {
                                                            kind: 'article-card',
                                                            dataDefinitionArgs:
                                                                {
                                                                    type: 'curation',
                                                                    name: 'federal-election-2025',
                                                                    offset: 0,
                                                                    pageSize: 1,
                                                                },
                                                            adTargeting:
                                                                getAdTargeting(
                                                                    'home',
                                                                    'default',
                                                                    seoTitle,
                                                                ),
                                                            imageFetchPriority:
                                                                'high',
                                                            disableImageLazyLoad:
                                                                true,
                                                        },
                                                    }),
                                                ],
                                                afterAside: [],
                                                aside: [
                                                    layout.nestedComposition({
                                                        type: 'box',
                                                        props: {
                                                            horizontalSpacing:
                                                                'outerMargin',
                                                            horizontalSpacingBreakpoint:
                                                                'sm',
                                                        },
                                                        contentAreas: {
                                                            main: [
                                                                layout.component(
                                                                    {
                                                                        type: 'juliet',
                                                                        props: {
                                                                            cardType:
                                                                                {
                                                                                    type: 'landscape',
                                                                                    format: 'landscape-common',
                                                                                },
                                                                            imageWidths:
                                                                                {
                                                                                    mobile: '80px',
                                                                                    tablet: '80px',
                                                                                    desktop:
                                                                                        '96px',
                                                                                    fallbackWidth: 96,
                                                                                    mode: 'fixed',
                                                                                },
                                                                            verticalSpacing:
                                                                                'md',
                                                                            removeHorizontalGutters:
                                                                                true,
                                                                            removeTopListPadding:
                                                                                true,
                                                                            hideByline:
                                                                                false,
                                                                            topicLevel:
                                                                                'parent',
                                                                            hideLastDivider:
                                                                                true,
                                                                            condensedInfo:
                                                                                true,
                                                                            dataDefinitionArgs:
                                                                                {
                                                                                    type: 'curation',
                                                                                    name: 'federal-election-2025',
                                                                                    offset: 1,
                                                                                    pageSize: 6,
                                                                                },
                                                                        },
                                                                    },
                                                                ),
                                                            ],
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                        layout.component({
                                            type: 'ad-unit',
                                            props: {
                                                adType: 'inline',
                                                noticePosition: 'above-center',
                                                slot: {
                                                    id: '0-election-1',
                                                    size: 'leaderboardAbove768MrecBelow',
                                                },
                                                padding: [0, 0, 0, 0],
                                                lazyLoadingDistance: 0,
                                            },
                                        }),
                                        ...(isTheRaceEnabled
                                            ? [
                                                  layout.nestedComposition({
                                                      type: 'box',
                                                      props: {
                                                          horizontalSpacing:
                                                              'outerMargin',
                                                          horizontalSpacingBreakpoint:
                                                              'lg',
                                                      },
                                                      contentAreas: {
                                                          main: [
                                                              layout.component({
                                                                  type: 'election-the-race-widget',
                                                                  props: {
                                                                      electionDefinition,
                                                                  },
                                                              }),
                                                          ],
                                                      },
                                                  }),
                                              ]
                                            : []),
                                        ...(isTheSeatsEnabled
                                            ? [
                                                  layout.nestedComposition({
                                                      type: 'box',
                                                      props: {
                                                          horizontalSpacing:
                                                              'outerMargin',
                                                          horizontalSpacingBreakpoint:
                                                              'lg',
                                                      },
                                                      contentAreas: {
                                                          main: [
                                                              layout.component({
                                                                  type: 'election-seat-widget',
                                                                  props: {
                                                                      electoratePageUrl: `/politics/${electionDefinition.electionId}/electorates`,
                                                                      electionDefinition,
                                                                  },
                                                              }),
                                                          ],
                                                      },
                                                  }),
                                              ]
                                            : []),
                                        ...(isMapEnabled
                                            ? [
                                                  layout.component({
                                                      type: 'election-map-overview-widget',
                                                      feature:
                                                          'federal-election-landing-page-map',
                                                      props: {
                                                          headingLevel: 'h2',
                                                          titleText:
                                                              'Map Overview',
                                                          electionDefinition,
                                                      },
                                                  }),
                                              ]
                                            : []),
                                    ],
                                },
                            }),
                        ],
                    },
                }),
                //topic listing
                layout.nestedComposition({
                    type: 'luna',
                    props: {
                        hasBeforeAside: false,
                        dividerConfig: {
                            column: {
                                hasAsideAndAfterAsideDivider: false,
                            },
                        },
                    },
                    contentAreas: {
                        beforeAside: [],
                        aside: [
                            layout.nestedComposition({
                                type: 'box',
                                props: {
                                    stickyOffset:
                                        'calc(var(--stickyHeaderHeight) + 80px)',
                                },
                                contentAreas: {
                                    main: [
                                        layout.nestedComposition({
                                            type: 'tag-link-navigation',
                                            props: {
                                                sectionNavItem: sectionNavItems(
                                                    isLatestNightlyOnEnabled,
                                                )[section],
                                            },
                                            contentAreas: {
                                                trailingContent: [
                                                    layout.component({
                                                        type: 'ad-unit',
                                                        props: {
                                                            adType: 'inline',
                                                            noticePosition:
                                                                'above-center',
                                                            slot: {
                                                                id: 'sidebar',
                                                                size: 'above1280Mrec',
                                                            },
                                                        },
                                                    }),
                                                ],
                                            },
                                        }),
                                    ],
                                },
                            }),
                        ],
                        afterAside: [
                            layout.component({
                                type: 'topic-section-card-list',
                                props: {
                                    dataDefinitionArgs: {
                                        type: 'context',
                                        contextId: MAIN_CONTENT_ID,
                                        section: section,
                                    },
                                    expectedItemCount: 15,
                                    adUnits: 3,
                                    adTargeting: getAdTargeting(
                                        'home',
                                        'default',
                                        seoTitle,
                                    ),
                                },
                            }),
                        ],
                    },
                }),
                layout.component({
                    type: 'pagination',
                    props: {
                        contextId: MAIN_CONTENT_ID,
                    },
                }),
                layout.component({
                    type: 'nightly-button',
                    props: {
                        variant: 'default',
                        text: 'BACK TO TOP',
                        color: 'primary',
                        fill: 'text',
                        layout: {
                            alignSelf: 'center',
                        },
                        action: {
                            type: 'button',
                            onClick: () =>
                                window.scrollTo({
                                    top: 0,
                                }),
                        },
                        icon: {
                            iconPosition: 'right',
                            IconElement: <IconChevronUpTN />,
                        },
                    },
                }),
            ],
            {
                type: 'listing',
                topics: [section],
                includeSubTopics: true,
                publicationKind: 'article',
                source: undefined,
                classification: undefined,
                paging: {
                    page,
                    pageSize: 15,
                    pageOffset: 0,
                },
            },
        ),
    }
}
