import React from 'react'
import { SpecialEditionProps } from '../../SpecialEdition/SpecialEdition'
import { WebLink } from '@news-mono/web-common'
import {
    StyledEditionImage,
    StyledEditionItem,
    StyledItemDescription,
    StyledItemDetail,
} from './EditionItem.styled'
import { format } from 'date-fns'

export const SpecialEditionItem: React.FC<SpecialEditionProps> = ({
    title,
    teaser,
    link,
    imageURL,
    lazyloadImages,
    publicationDate,
}) => {
    return (
        <StyledEditionItem>
            {imageURL && (
                <WebLink to={link}>
                    <StyledEditionImage
                        src={imageURL}
                        alt={title}
                        loading={lazyloadImages ? 'lazy' : undefined}
                    />
                </WebLink>
            )}

            <StyledItemDetail>
                <time dateTime={publicationDate}>
                    {format(new Date(publicationDate), 'd MMM yyyy')}
                </time>
                <StyledItemDescription>{teaser}</StyledItemDescription>
            </StyledItemDetail>
        </StyledEditionItem>
    )
}
