import { TheNightlyNavItem } from '@news-mono/component-library'
import { NavItem } from '@news-mono/web-common'
import { RoutableNightlySection } from '@west-australian-newspapers/publication-types'
import {
    defaultOnEdition,
    latestOnEdition,
} from './routes/thenightly-on/onEditionsConfig'

type GetLatestNavItemsArgs = {
    inApp: boolean
    theNightlyOnTopicEnabled: boolean
    isLatestNightlyOnEnabled: boolean
    isDefaultNightlyOnEnabled: boolean
}

export const getLatestNavItems = ({
    inApp,
    theNightlyOnTopicEnabled,
    isLatestNightlyOnEnabled,
    isDefaultNightlyOnEnabled,
}: GetLatestNavItemsArgs): TheNightlyNavItem[] => [
    {
        name: 'Home',
        link: '/',
        section: 'default',
    },
    ...(inApp
        ? []
        : [
              {
                  name: 'Latest Edition',
                  link: '/editions',
                  section: 'default' as const,
                  forceInternalSSR: true,
              },
          ]),
    ...(theNightlyOnTopicEnabled && isLatestNightlyOnEnabled
        ? [
              {
                  name: 'The Nightly On',
                  link: latestOnEdition.path,
                  section: 'default' as const,
              },
          ]
        : []),
    ...(theNightlyOnTopicEnabled &&
    !isLatestNightlyOnEnabled &&
    isDefaultNightlyOnEnabled
        ? [
              {
                  name: 'The Nightly On',
                  link: defaultOnEdition.path,
                  section: 'default' as const,
              },
          ]
        : []),
    ...(inApp
        ? []
        : [
              {
                  name: 'Puzzles',
                  link: '/puzzles',
                  section: 'default' as const,
              },
          ]),
    {
        name: 'Podcasts',
        link: '/podcasts',
        section: 'podcasts',
    },
    {
        name: 'Video',
        link: '/video',
        section: 'video',
    },
]

export const getCommonNavItems = (inApp: boolean): TheNightlyNavItem[] => [
    {
        name: 'About',
        link: '/about',
        section: 'default',
    },
    ...(!inApp
        ? ([
              {
                  name: 'Contact',
                  link: '/contact',
                  section: 'default',
              },
          ] as TheNightlyNavItem[])
        : []),
    {
        name: 'Editorial policy',
        link: 'https://www.sevenwestmedia.com.au/assets/pdfs/Group-Editorial-Policy2.pdf',
        section: 'default',
    },
]

export const myAccountNavItems: TheNightlyNavItem[] = [
    {
        name: 'Saved articles',
        link: '/saved-articles',
        section: 'default',
    },
]

export const headerNavItems = (
    isLatestNightlyOnEnabled: boolean,
): TheNightlyNavItem[] => [
    {
        name: 'Federal Election 2025',
        section: 'politics',
        link: '/politics/federal-election-2025',
        feature: 'federal-election-sitewide-navigation',
    },
    {
        name: 'Politics',
        section: 'politics',
        link: '/politics',
    },
    {
        name: 'Australia',
        section: 'australia',
        link: '/australia',
    },
    {
        name: 'Business',
        section: 'business',
        link: '/business',
    },
    {
        name: 'Opinion',
        section: 'opinion',
        link: '/opinion',
    },
    {
        name: 'Culture',
        section: 'culture',
        link: '/culture',
    },
    {
        name: 'Travel',
        section: 'travel',
        link: '/lifestyle/travel',
    },
    {
        name: 'Sport',
        section: 'sport',
        link: '/sport',
    },
    {
        name: 'World',
        section: 'world',
        link: '/world',
    },
    {
        name: 'The Nightly On',
        section: 'on',
        link: isLatestNightlyOnEnabled
            ? latestOnEdition.path
            : defaultOnEdition.path,
        feature: 'the-nightly-on-topic',
    },
    {
        name: 'The Front Dore',
        section: 'opinion',
        link: '/opinion/the-front-dore',
    },
]

const sectionDisplayNameMap: Record<RoutableNightlySection, string> = {
    australia: 'Australia',
    opinion: 'Opinion',
    world: 'World',
    politics: 'Politics',
    business: 'Business',
    sport: 'Sport',
    society: 'Society',
    culture: 'Culture',
    lifestyle: 'Lifestyle',
    travel: 'Travel',
    from: 'From', // NYT is the only external content provider as of this comment.
    podcasts: 'Podcasts',
    video: 'Video',
    'best-australian-yarn': 'The Best Australian Yarn',
    'us-politics': 'US Politics',
    on: 'The Nightly On',
}

// https://wanews.atlassian.net/wiki/spaces/REF/pages/130777119/Taxonomy+-+final+and+approved
// These must match the CUE subtopic ID for consistency!
const subTopicDisplayNameMap = {
    politics: {
        australia: 'Australia',
        'us-politics': 'US Politics',
        world: 'World',
    },
    australia: {
        nsw: 'NSW',
        victoria: 'Victoria',
        queensland: 'Queensland',
        'south-australia': 'South Australia',
        'western-australia': 'Western Australia',
        act: 'ACT',
        tasmania: 'Tasmania',
        'northern-territory': 'Northern Territory',
    },
    business: {
        companies: 'Companies',
        markets: 'Markets',
        wealth: 'Wealth',
        mining: 'Mining',
        energy: 'Energy',
    },
    opinion: {
        editorial: 'Editorial',
        'the-front-dore': 'The Front Dore',
        political: 'Political',
        sport: 'Sport',
        'up-late': 'Up Late',
        cartoon: 'Cartoon',
    },
    culture: {
        film: 'Film',
        tv: 'TV',
        music: 'Music',
        'pop-culture': 'Pop culture',
        'visual-arts': 'Visual arts',
        gaming: 'Gaming',
        radio: 'Radio',
        books: 'Books',
        'best-australian-yarn': 'The Best Australian Yarn',
    },
    society: {
        health: 'Health',
        education: 'Education',
        'climate-change': 'Climate Change',
        science: 'Science',
        technology: 'Technology',
    },
    lifestyle: {
        travel: 'Travel',
        wellbeing: 'Wellbeing',
        property: 'Property',
        food: 'Food',
        wine: 'Wine',
        motoring: 'Motoring',
        home: 'Home',
        garden: 'Garden',
        fashion: 'Fashion',
    },
    sport: {
        afl: 'AFL',
        nrl: 'NRL',
        cricket: 'Cricket',
        tennis: 'Tennis',
        football: 'Football',
        'horse-racing': 'Horse Racing',
        'formula-one': 'Formula One',
        rugby: 'Rugby Union',
        other: 'Other',
    },
    world: {
        africa: 'Africa',
        americas: 'Americas',
        'asia-pacific': 'Asia Pacific',
        europe: 'Europe',
        'middle-east': 'Middle East',
        usa: 'USA',
        uk: 'UK',
    },
    from: {
        'the-new-york-times': 'The New York Times',
    },
    podcasts: {
        'the-nightly-five': 'The Nightly Five',
        'wine-chats': 'Wine Chats',
    },
    video: {
        'gerard-ross': 'The Boy in the Blue Cap',
        'foul-play': 'Foul Play: The Tiny Pinder Story',
    },
    on: {
        leadership: 'Leadership',
        tomorrow: 'Tomorrow',
    },
} as const

/**
 * Subtopic NavItems for each Nightly Section.
 */
export const subTopicNavItems: Record<RoutableNightlySection, NavItem[]> = {
    politics: [
        {
            name: subTopicDisplayNameMap.politics.australia,
            link: '/politics/australia',
        },
        {
            name: subTopicDisplayNameMap.politics['us-politics'],
            link: '/politics/us-politics',
        },
        {
            name: subTopicDisplayNameMap.politics.world,
            link: '/politics/world',
        },
    ],
    australia: [
        {
            name: subTopicDisplayNameMap.australia.nsw,
            link: '/australia/nsw',
        },
        {
            name: subTopicDisplayNameMap.australia.victoria,
            link: '/australia/victoria',
        },
        {
            name: subTopicDisplayNameMap.australia.queensland,
            link: '/australia/queensland',
        },
        {
            name: subTopicDisplayNameMap.australia['south-australia'],
            link: '/australia/south-australia',
        },
        {
            name: subTopicDisplayNameMap.australia['western-australia'],
            link: '/australia/western-australia',
        },
        {
            name: subTopicDisplayNameMap.australia.act,
            link: '/australia/act',
        },
        {
            name: subTopicDisplayNameMap.australia.tasmania,
            link: '/australia/tasmania',
        },
        {
            name: subTopicDisplayNameMap.australia['northern-territory'],
            link: '/australia/northern-territory',
        },
    ],
    business: [
        {
            name: subTopicDisplayNameMap.business.companies,
            link: '/business/companies',
        },
        {
            name: subTopicDisplayNameMap.business.markets,
            link: '/business/markets',
        },
        {
            name: subTopicDisplayNameMap.business.wealth,
            link: '/business/wealth',
        },
        {
            name: subTopicDisplayNameMap.business.mining,
            link: '/business/mining',
        },
        {
            name: subTopicDisplayNameMap.business.energy,
            link: '/business/energy',
        },
    ],
    opinion: [
        {
            name: subTopicDisplayNameMap.opinion.editorial,
            link: '/opinion/editorial',
        },
        {
            name: subTopicDisplayNameMap.opinion['the-front-dore'],
            link: '/opinion/the-front-dore',
        },
        {
            name: subTopicDisplayNameMap.opinion.political,
            link: '/opinion/political',
        },
        {
            name: subTopicDisplayNameMap.opinion.sport,
            link: '/opinion/sport',
        },
        {
            name: subTopicDisplayNameMap.opinion['up-late'],
            link: '/opinion/up-late',
        },
        {
            name: subTopicDisplayNameMap.opinion.cartoon,
            link: '/opinion/cartoon',
        },
    ],
    from: [],
    culture: [
        {
            name: subTopicDisplayNameMap.culture.film,
            link: '/culture/film',
        },
        {
            name: subTopicDisplayNameMap.culture.tv,
            link: '/culture/tv',
        },
        {
            name: subTopicDisplayNameMap.culture.music,
            link: '/culture/music',
        },
        {
            name: subTopicDisplayNameMap.culture['pop-culture'],
            link: '/culture/pop-culture',
        },
        {
            name: subTopicDisplayNameMap.culture['visual-arts'],
            link: '/culture/visual-arts',
        },
        {
            name: subTopicDisplayNameMap.culture.gaming,
            link: '/culture/gaming',
        },
        {
            name: subTopicDisplayNameMap.culture.radio,
            link: '/culture/radio',
        },
        {
            name: subTopicDisplayNameMap.culture.books,
            link: '/culture/books',
        },
        {
            name: subTopicDisplayNameMap.culture['best-australian-yarn'],
            link: '/culture/best-australian-yarn',
        },
    ],
    'best-australian-yarn': [],
    society: [
        {
            name: subTopicDisplayNameMap.society.health,
            link: '/society/health',
        },
        {
            name: subTopicDisplayNameMap.society.education,
            link: '/society/education',
        },
        {
            name: subTopicDisplayNameMap.society['climate-change'],
            link: '/society/climate-change',
        },
        {
            name: subTopicDisplayNameMap.society.science,
            link: '/society/science',
        },
        {
            name: subTopicDisplayNameMap.society.technology,
            link: '/society/technology',
        },
    ],
    travel: [],
    lifestyle: [
        {
            name: subTopicDisplayNameMap.lifestyle.travel,
            link: '/lifestyle/travel',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.wellbeing,
            link: '/lifestyle/wellbeing',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.property,
            link: '/lifestyle/property',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.food,
            link: '/lifestyle/food',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.wine,
            link: '/lifestyle/wine',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.motoring,
            link: '/lifestyle/motoring',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.home,
            link: '/lifestyle/home',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.garden,
            link: '/lifestyle/garden',
        },
        {
            name: subTopicDisplayNameMap.lifestyle.fashion,
            link: '/lifestyle/fashion',
        },
    ],
    sport: [
        {
            name: subTopicDisplayNameMap.sport.afl,
            link: '/sport/afl',
        },
        {
            name: subTopicDisplayNameMap.sport.nrl,
            link: '/sport/nrl',
        },
        {
            name: subTopicDisplayNameMap.sport.cricket,
            link: '/sport/cricket',
        },
        {
            name: subTopicDisplayNameMap.sport.tennis,
            link: '/sport/tennis',
        },
        {
            name: subTopicDisplayNameMap.sport.football,
            link: '/sport/football',
        },
        {
            name: subTopicDisplayNameMap.sport['horse-racing'],
            link: '/sport/horse-racing',
        },
        {
            name: subTopicDisplayNameMap.sport['formula-one'],
            link: '/sport/formula-one',
        },
        {
            name: subTopicDisplayNameMap.sport.rugby,
            link: '/sport/rugby-union',
        },
        {
            name: subTopicDisplayNameMap.sport.other,
            link: '/sport/other',
        },
    ],
    world: [
        {
            name: subTopicDisplayNameMap.world.africa,
            link: '/world/africa',
        },
        {
            name: subTopicDisplayNameMap.world.americas,
            link: '/world/americas',
        },
        {
            name: subTopicDisplayNameMap.world['asia-pacific'],
            link: '/world/asia-pacific',
        },
        {
            name: subTopicDisplayNameMap.world.europe,
            link: '/world/europe',
        },
        {
            name: subTopicDisplayNameMap.world['middle-east'],
            link: '/world/middle-east',
        },
        {
            name: subTopicDisplayNameMap.world.usa,
            link: '/world/usa',
        },
        {
            name: subTopicDisplayNameMap.world.uk,
            link: '/world/uk',
        },
    ],
    podcasts: [
        {
            name: subTopicDisplayNameMap.podcasts['the-nightly-five'],
            link: '/podcasts/the-nightly-five',
        },
        {
            name: subTopicDisplayNameMap.podcasts['wine-chats'],
            link: '/podcasts/wine-chats',
        },
    ],
    video: [
        {
            name: subTopicDisplayNameMap.video['foul-play'],
            link: '/video/foul-play',
        },
        {
            name: subTopicDisplayNameMap.video['gerard-ross'],
            link: '/video/gerard-ross',
        },
    ],
    'us-politics': [],
    on: [
        {
            name: subTopicDisplayNameMap.on.tomorrow,
            link: 'on/tomorrow',
        },
    ],
}

/**
 * All NavItems for each Nightly Section. Incl 'All' Links, etc.
 */
export const sectionNavItems = (
    isLatestNightlyOnEnabled: boolean,
): Record<RoutableNightlySection, TheNightlyNavItem> => ({
    politics: {
        name: sectionDisplayNameMap.politics,
        link: '/politics',
        section: 'politics',
        subNavLinks: [
            {
                name: 'All Politics',
                link: '/politics',
            },
            {
                name: 'Federal Election 2025',
                link: '/politics/federal-election-2025',
                feature: 'federal-election-sitewide-navigation',
            },
            ...subTopicNavItems.politics,
        ],
    },
    australia: {
        name: sectionDisplayNameMap.australia,
        link: '/australia',
        section: 'australia',
        subNavLinks: [
            {
                name: 'All Australia',
                link: '/australia',
            },
            ...subTopicNavItems.australia,
        ],
    },
    business: {
        name: sectionDisplayNameMap.business,
        link: '/business',
        section: 'business',
        subNavLinks: [
            {
                name: 'All Business',
                link: '/business',
            },
            ...subTopicNavItems.business,
        ],
    },
    travel: {
        name: sectionDisplayNameMap.travel,
        link: '/lifestyle/travel',
        section: 'travel',
        subNavLinks: [
            {
                name: 'All Travel',
                link: '/lifestyle/travel',
            },
            ...subTopicNavItems.travel,
        ],
        hideFromFlyoutMenu: false,
    },
    opinion: {
        name: sectionDisplayNameMap.opinion,
        link: '/opinion',
        section: 'opinion',
        subNavLinks: [
            {
                name: 'All Opinion',
                link: '/opinion',
            },
            ...subTopicNavItems.opinion,
        ],
    },
    from: {
        name: subTopicDisplayNameMap.from['the-new-york-times'], // ! WARNING !
        link: '/from/the-new-york-times', // This is because NYT is the only one so far.
        section: 'from', // Taxonomy may need changes if things are assumed incorrectly.
        subNavLinks: [
            {
                name: 'All Articles',
                link: '/from/the-new-york-times',
            },
        ],
    },
    culture: {
        name: sectionDisplayNameMap.culture,
        link: '/culture',
        section: 'culture',
        subNavLinks: [
            {
                name: 'All Culture',
                link: '/culture',
            },
            ...subTopicNavItems.culture,
        ],
    },
    // Workaround section for sub-level BAY topic / curation display on homepage
    'best-australian-yarn': {
        name: sectionDisplayNameMap['best-australian-yarn'],
        link: '/culture/best-australian-yarn',
        section: 'culture',
        subNavLinks: [
            {
                name: 'All Articles',
                link: '/culture/best-australian-yarn',
            },
        ],
        hideFromFlyoutMenu: true, // Subtopic of Culture
    },
    society: {
        name: sectionDisplayNameMap.society,
        link: '/society',
        section: 'society',
        subNavLinks: [
            {
                name: 'All Society',
                link: '/society',
            },
            ...subTopicNavItems.society,
        ],
    },
    lifestyle: {
        name: sectionDisplayNameMap.lifestyle,
        link: '/lifestyle',
        section: 'lifestyle',
        subNavLinks: [
            {
                name: 'All Lifestyle',
                link: '/lifestyle',
            },
            ...subTopicNavItems.lifestyle,
        ],
    },
    sport: {
        name: sectionDisplayNameMap.sport,
        link: '/sport',
        section: 'sport',
        subNavLinks: [
            {
                name: 'All Sport',
                link: '/sport',
            },
            ...subTopicNavItems.sport,
        ],
    },
    world: {
        name: sectionDisplayNameMap.world,
        link: '/world',
        section: 'world',
        subNavLinks: [
            {
                name: 'All World',
                link: '/world',
            },
            ...subTopicNavItems.world,
        ],
    },
    podcasts: {
        name: sectionDisplayNameMap.podcasts,
        link: '/podcasts',
        section: 'podcasts',
        subNavLinks: [
            {
                name: 'All Podcasts',
                link: '/podcasts',
            },
            ...subTopicNavItems.podcasts,
        ],
        hideFromFlyoutMenu: true,
    },
    video: {
        name: sectionDisplayNameMap.video,
        link: '/video',
        section: 'video',
        subNavLinks: [
            {
                name: 'All Video',
                link: '/video',
            },
            ...subTopicNavItems.video,
        ],
        hideFromFlyoutMenu: true,
    },
    'us-politics': {
        name: sectionDisplayNameMap['us-politics'],
        link: '/politics/us-politics',
        section: 'politics',
        subNavLinks: [
            {
                name: 'All Articles',
                link: '/politics/us-politics',
            },
        ],
        hideFromFlyoutMenu: true,
    },
    on: {
        name: sectionDisplayNameMap.on,
        link: isLatestNightlyOnEnabled
            ? latestOnEdition.path
            : defaultOnEdition.path,
        section: 'on',
        hideFromFlyoutMenu: true,
        subNavLinks: [...subTopicNavItems.on],
    },
})
