import { TheNightlySection } from '@news-mono/common'
import {
    IconChevronUpTN,
    metrics,
    sectionThemeOverrider,
    theNightlySmallSectionHeaderOverride,
} from '@news-mono/component-library'
import {
    AdTargeting,
    Features,
    PageType,
    StaticRoutes,
} from '@news-mono/web-common'
import { CheckedComponentInformation } from 'json-react-layouts'
import { layout } from '../../App.routing'
import React from 'react'
import { sectionNavItems, subTopicNavItems } from '../../navigation'
import { newsletterBanner } from '../publication/kind/article'
import { cardList } from '../home/get-cardList-segment'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { latestOnEdition } from '../thenightly-on/onEditionsConfig'
import { Store } from 'redux'
import { is } from 'ramda'

const topics = {
    primary: {
        id: 'sport/olympics',
        title: 'Olympics',
        metadata: {},
        seoTitle: '',
        parent: {
            id: 'sport',
            title: 'Sport',
            metadata: {},
            seoTitle: '',
        },
    },
    secondary: [],
}

function createOlympicPage(
    pageTitle: string,
    description: string,
    adTargeting: AdTargeting,
    contentComponentType: Array<CheckedComponentInformation>,
    section: TheNightlySection = 'default',
    store: Store,
): PageType<TheNightlySection> {
    if (section === 'default') {
        throw new Error("'default' section does not exist.")
    }

    const isLatestNightlyOnEnabled = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        latestOnEdition.featureToggle as Features,
    )

    return {
        kind: 'page',
        heading: pageTitle,
        pageType: 'topic',
        section,
        adTargeting,
        hideHeading: true,
        pageMeta: {
            description,
        },
        socialMeta: {
            description,
        },
        compositions: [
            layout.composition({
                type: 'scheme-overrider',
                props: {
                    override: sectionThemeOverrider('thenightly', section),
                },

                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'luna',
                            props: {
                                hasAfterAside: false,
                                asideSide: 'right',
                            },
                            contentAreas: {
                                beforeAside: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            horizontalSpacingBreakpoint: 'sm',
                                            horizontalSpacing: 'md',
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'thenightly-breadcrumb',
                                                    props: {
                                                        topics,
                                                        nightlySectionNavItems:
                                                            sectionNavItems(
                                                                isLatestNightlyOnEnabled,
                                                            ),
                                                        subTopicNavItems:
                                                            subTopicNavItems,
                                                    },
                                                }),
                                                ...contentComponentType,
                                                layout.component({
                                                    type: 'ad-unit',
                                                    props: {
                                                        noticePosition:
                                                            'below-center',
                                                        hiddenUntilLoaded:
                                                            false,
                                                        padding: [
                                                            60,
                                                            0,
                                                            metrics.thenightly
                                                                .margins.xxl,
                                                            0,
                                                        ],
                                                        slot: {
                                                            id: 'leaderboard-two',
                                                            size: 'leaderboard768Above',
                                                        },
                                                        adType: 'inline',
                                                        lazyLoadingDistance: 0,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                                afterAside: [],
                                aside: [
                                    layout.nestedComposition({
                                        type: 'scheme-overrider',
                                        props: {
                                            override: sectionThemeOverrider(
                                                'thenightly',
                                                section,
                                            ),
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        hasTopSpacing: true,
                                                        verticalSpacing:
                                                            'gridGap',
                                                        nightlyArticleSidebar:
                                                            true,
                                                        horizontalSpacingBreakpoint:
                                                            'sm',
                                                        horizontalSpacing: 'lg',
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            getMoreLikeThis(),
                                                            newsletterBanner(),
                                                            cardList(
                                                                {
                                                                    type: 'curation',
                                                                    name: 'top-stories',
                                                                    offset: 0,
                                                                    pageSize: 5,
                                                                },
                                                                'The Top 5',
                                                            ),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                flex: {
                                    default: {
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'nightly-button',
                                        props: {
                                            variant: 'default',
                                            text: 'BACK TO TOP',
                                            color: 'primary',
                                            fill: 'text',
                                            layout: {
                                                alignSelf: 'center',
                                            },
                                            action: {
                                                type: 'button',
                                                onClick: () =>
                                                    window.scrollTo({ top: 0 }),
                                            },
                                            icon: {
                                                iconPosition: 'right',
                                                IconElement: (
                                                    <IconChevronUpTN />
                                                ),
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

export const olympicContentRoutes: StaticRoutes<TheNightlySection> = {
    '/sport/olympics/medal-tally': ({ getAdTargeting, store }) => {
        const enableRoute = isFeatureEnabled(
            toFeatureState(store.getState().toggles),
            'paris-olympics-widgets',
        )
        if (!enableRoute) {
            return null
        }

        return createOlympicPage(
            'Olympics',
            'Olympic | The Nightly',
            getAdTargeting('home', 'sport', 'olympics'),
            [
                layout.component({
                    type: 'olympics-medal-tally',
                    props: {
                        size: 'full',
                        listSize: 50,
                        hideLinks: true,
                        svgColourOverride: '#06671e',
                    },
                }),
            ],
            'sport',
            store,
        )
    },
}

/** Not the same as the `More Like This` on articles as that requires a publication ID.
 * Instead we just pull from the sport/olympics topic :p
 */
const getMoreLikeThis = () =>
    layout.nestedComposition({
        type: 'component-overrider',
        props: {
            override: () => theNightlySmallSectionHeaderOverride,
        },
        contentAreas: {
            children: [
                layout.component({
                    type: 'juliet',
                    props: {
                        cardType: {
                            type: 'landscape',
                            format: 'landscape-common',
                        },
                        verticalSpacing: 'md',
                        removeHorizontalGutters: true,
                        hideByline: false,
                        topicLevel: 'parent',
                        numbered: false,
                        sectionHeader: {
                            heading: 'More Like This',
                        },
                        dataDefinitionArgs: {
                            type: 'listing',
                            topics: ['sport/olympics'],
                            publicationKind: 'article',
                            paging: {
                                page: 1,
                                pageSize: 5,
                            },
                        },
                    },
                }),
            ],
        },
    })
