import { AllEvents, DataLayerEventName } from '@news-mono/web-common'
import React from 'react'
import { ElectionFilter } from '../components'
import { ElectionSearchInput } from '../components/ElectionSearch'
import { TheSeatsDivider } from '../TheSeatsWidget/TheSeatsWidget.styled'
import { ElectionFilterContext } from '../../../contexts'
import { SidebarFilterContainer } from './TNElectionSidebarFilter.styled'

export interface TNElectionSidebarFilterProps {
    onEvent: (event: AllEvents) => void
    topMarginOverride?: number
    isSearchEnabled?: boolean
    isAlphabeticalEnabled?: boolean
    isStateEnabled?: boolean
}

export const TNElectionSidebarFilter = ({
    onEvent,
    isStateEnabled = true,
    isSearchEnabled = false,
    isAlphabeticalEnabled = false,
    topMarginOverride,
}: TNElectionSidebarFilterProps): JSX.Element => {
    const { search, sort, state } = React.useContext(ElectionFilterContext)

    return (
        <SidebarFilterContainer topMarginOverride={topMarginOverride}>
            {isSearchEnabled && (
                <ElectionSearchInput
                    onEvent={onEvent}
                    electionFilter={search}
                />
            )}
            {isAlphabeticalEnabled && (
                <ElectionFilter
                    headingText="Sort by"
                    dataLayerEventOriginator="ElectionSort"
                    onEvent={onEvent}
                    dataLayerEventName={DataLayerEventName.searchFilter}
                    electionFilter={sort}
                />
            )}
            {isStateEnabled && (
                <ElectionFilter
                    headingText="State"
                    dataLayerEventOriginator="ElectionStateFilter"
                    onEvent={onEvent}
                    dataLayerEventName={DataLayerEventName.searchFilter}
                    electionFilter={state}
                />
            )}
            <TheSeatsDivider />
        </SidebarFilterContainer>
    )
}
