import { TheNightlySection } from '@news-mono/common'
import { breakpoint, calcRem, metrics } from '@news-mono/component-library'
import {
    getElectionAdPageKindFromElectionId,
    ElectionDefinition,
    GetRouteAdTargeting,
    getSocialImageMeta,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getYear } from 'date-fns'
import { wrapWithElectionContext } from '../routes'

type CreateElectionPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
}

export const createElectionElectoratePage = ({
    getAdTargeting,
    electionDefinition,
}: CreateElectionPageArgs): PageType<TheNightlySection> => {
    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'

    const electionYear = getYear(
        new Date(electionDefinition.electionData?.config?.electionDate ?? ''),
    )
    const electionState = electionDefinition.electionData?.config?.state
    const electionPageKind = getElectionAdPageKindFromElectionId(
        electionDefinition.electionId,
    )

    const meta = electionDefinition.meta

    const section = 'politics'

    let defaultTitle
    let defaultDescription

    if (isFederal) {
        defaultTitle = `Federal election ${electionYear} electorate results`
        defaultDescription = `Full Australian election ${electionYear} results: See all the votes and how every seat across Australia was won in the ${electionYear} Federal election.`
    } else {
        defaultTitle = `${electionState} State election ${electionYear} electorate results`
        defaultDescription = `Full ${electionState} State election ${electionYear} results: See all the votes and how every seat across ${electionState} was won in the ${electionYear} State election.`
    }

    const seoTitle = meta.electorates?.seoTitle ?? defaultTitle
    const seoDescription =
        meta.electorates?.seoDescription ?? defaultDescription

    const adTargeting = getAdTargeting(
        electionPageKind ?? 'home',
        section,
        electionDefinition.electionId,
    )

    // as per https://swmdigital-wa.slack.com/archives/C07TZDGREDA/p1741412941432569?thread_ts=1741411065.813789&cid=C07TZDGREDA
    // not sure if this is the best way to do it, so we should fix it up later
    adTargeting.ssAdUnits = adTargeting.ssAdUnits
        .flatMap((adUnit) => adUnit.split('/'))
        .map((adUnit) =>
            adUnit === 'WAelection2025' ? 'WA election 2025' : adUnit,
        )

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: section,
        hideHeading: true,
        adTargeting,
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}/electorates`,
                      },
                  ]
                : undefined,
            meta: meta.electorates?.socialImage
                ? getSocialImageMeta(
                      meta.electorates.socialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        additionalPageProperties: {
            hasTopGutter: false,
        },
        compositions: wrapWithElectionContext([
            layout.nestedComposition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thenightly.siteMetrics.mainContentWidth,
                    fullWidth: true,
                    flex: {
                        default: {
                            flexDirection: 'column',
                        },
                        sm: {},
                    },
                },
                contentAreas: {
                    main: [
                        layout.component({
                            type: 'election-navigation',
                            props: {
                                electionDefinition,
                                activePage: 'electorate-results',
                                headingLevel: 'h2',
                            },
                        }),
                        layout.nestedComposition({
                            type: 'luna',
                            props: {
                                asideSide: 'left',
                                hasAfterAside: true,
                                hasBeforeAside: true,
                                dividerConfig: {
                                    column: {
                                        hasBeforeAsideAndAsideDivider: false,
                                        hasAsideAndAfterAsideDivider: false,
                                    },
                                    split: {
                                        hasBeforeAsideAndAfterAsideDivider:
                                            false,
                                    },
                                },
                            },
                            contentAreas: {
                                beforeAside: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            horizontalSpacing: 'outerMargin',
                                            horizontalSpacingBreakpoint: 'lg',
                                            verticalSpacing: 'md',
                                            flex: {
                                                default: {
                                                    flexDirection: 'column',
                                                    paddingTop: calcRem(40),
                                                    marginBottom: calcRem(-24),
                                                },
                                                lg: {
                                                    paddingTop: calcRem(80),
                                                    marginBottom: calcRem(-40),
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'tn-electorate-header',
                                                    props: {
                                                        electionDefinition,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                                afterAside: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            horizontalSpacing: 'outerMargin',
                                            horizontalSpacingBreakpoint: 'lg',
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'election-full-electorate-widget',
                                                    props: {
                                                        electionDefinition,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                                aside: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            horizontalSpacing: 'outerMargin',
                                            horizontalSpacingBreakpoint: 'lg',
                                            flex: {
                                                default: {
                                                    flexDirection: 'column',
                                                    paddingTop: calcRem(16),
                                                    marginBottom: calcRem(10),
                                                },
                                                sm: {
                                                    marginBottom: calcRem(-10),
                                                },
                                                lg: {
                                                    paddingTop: calcRem(80),
                                                    marginBottom: 0,
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        stickyOffset:
                                                            'calc(var(--stickyHeaderHeight) + 80px)',
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            layout.component({
                                                                type: 'tn-election-sidebar-filter',
                                                                props: {
                                                                    isSearchEnabled:
                                                                        true,
                                                                    isStateEnabled:
                                                                        true,
                                                                    isAlphabeticalEnabled:
                                                                        true,
                                                                },
                                                            }),
                                                            layout.component({
                                                                type: 'ad-unit',
                                                                props: {
                                                                    adType: 'inline',
                                                                    noticePosition:
                                                                        'above-center',
                                                                    slot: {
                                                                        id: 'election-mrec-1',
                                                                        size: 'above1280Mrec',
                                                                    },
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ]),
    }
}
