import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import { getElectionToggles, TheNightlySection } from '@news-mono/common'
import {
    BaseClientConfig,
    ElectionDefinition,
    GetRouteAdTargeting,
    LoadedElectionDefinition,
    PageType,
    RouteResolution,
    Store,
} from '@news-mono/web-common'
import { createElectorateEmbedPage } from '../embeds/createElectorateEmbedPage'
import { createElectionLandingPage } from './createElectionLandingPage'
import H from 'history'
import { getTopicPageRouteInfo } from '../../topic/get-topic-page-route-info'
import { createElectionPartyTotalPage } from './createElectionPartyTotalsPage'
import { createElectionElectoratePage } from './createElectionElectoratePage'

export const createSpecificElectionPage = (
    resolution: RouteResolution,
    electionDefinition: LoadedElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
    location: H.Location<any>,
    config: BaseClientConfig,
): PageType<TheNightlySection> | null => {
    const toggles = store.getState().toggles
    try {
        if (
            resolution.type === 'static' &&
            resolution.resolution?.type === 'topic'
        ) {
            return getTopicPageRouteInfo({
                publicationKind: resolution.resolution.publicationKind,
                topic: resolution.resolution.topic,
                section: resolution.resolution.section as TheNightlySection,
                getAdTargeting,
                classification: resolution.resolution.classification,
                location,
                baseUrl: config.publicUrl,
                toggles,
            })
        }

        return createElectionLandingPage({
            getAdTargeting,
            electionDefinition,
            toggles,
            location,
        })
    } catch (error) {
        console.error({ error }, 'Error creating election landing page!')
        return null
    }
}

export const createSpecificPartyTotalsPage = (
    electionDefinition: LoadedElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
): PageType<TheNightlySection> | null => {
    // Override meta with that from taxonomy if provided.

    const { 'election-party-totals-route': isPartyTotalsEnabled } =
        getElectionToggles(electionDefinition.electionId)

    if (!isPartyTotalsEnabled) {
        return null
    }

    return createElectionPartyTotalPage({
        electionDefinition,
        getAdTargeting,
    })
}

export const createSpecificElectionEmbedPage = (
    electionDefinition: LoadedElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<TheNightlySection> | null => {
    const enableRoute = isFeatureEnabled(
        toFeatureState(store.getState().toggles),
        'election-widget-preview-page',
        true,
    )

    if (!enableRoute) {
        return null
    }

    return createElectorateEmbedPage({
        electionDefinition,
        getAdTargeting,
    })
}

export const createSpecificElectoratesPage = (
    electionDefinition: ElectionDefinition,
    getAdTargeting: GetRouteAdTargeting,
    store: Store,
): PageType<TheNightlySection> | null => {
    const { 'election-electorate-results-route': isElectoratesEnabled } =
        getElectionToggles(electionDefinition.electionId)

    if (!isElectoratesEnabled) {
        return null
    }

    return createElectionElectoratePage({
        getAdTargeting,
        electionDefinition: electionDefinition,
    })
}
