export type EditionConfig = {
    title: string
    heading: string
    teaser: string
    link: string
    imageURL: string
    socialImageURL: string
    lazyloadImages: boolean
    featureToggle: string
    alt: string
    publicationDate: string
    curation: string
    path: string
}

export const onEditionsConfig: Record<string, EditionConfig> = {
    'on/leadership': {
        title: 'Leadership',
        heading: 'Leadership Edition',
        teaser: 'A special magazine from The Nightly featuring some of the most important business leaders from Australia and around the world.',
        link: 'https://editions.thenightly.com.au/ccidist-replica-reader/?epub=https://editions.thenightly.com.au/ccidist-ws/the_nightly/the_nightly/issues/12678/#/pages/31',
        imageURL: require('./assets/leadership-edition-2024.png'),
        socialImageURL: require('./assets/leadership-edition-2024-social-image.jpg'),
        lazyloadImages: true,
        featureToggle: 'the-nightly-on-topic',
        alt: 'The Nightly Special Edition',
        publicationDate: '2024-10-24',
        curation: 'thenightly-on-leadership',
        path: '/on/leadership',
    },
    'on/tomorrow': {
        title: 'Tomorrow',
        heading: 'Tomorrow Edition 2025',
        teaser: 'A special magazine from The Nightly that delves into the issues that confront us now and how our lives, leaders and environment might change in the next 25 years.',
        link: 'https://editions.thenightly.com.au/ccidist-replica-reader/?epub=https://editions.thenightly.com.au/ccidist-ws/the_nightly/the_nightly/issues/15869/#/pages/43', // This is a redirect in Gaztte to allow us to update the Edition Page reference on-the-fly.
        imageURL: require('./assets/tomorrow-edition-2025.jpg'),
        socialImageURL: require('./assets/tomorrow-edition-2025-social-image.jpg'),
        lazyloadImages: true,
        featureToggle: 'the-nightly-on-tomorrow-route',
        alt: 'The Nightly Tomorrow Edition',
        publicationDate: '2025-03-20',
        curation: 'thenightly-on-tomorrow',
        path: '/on/tomorrow',
    },
}

export type EditionKeys = keyof typeof onEditionsConfig

export const isEditionsConfigKey = (key: string): key is EditionKeys => {
    return key in onEditionsConfig
}

export const defaultOnEdition = onEditionsConfig['on/leadership']
export const latestOnEdition = onEditionsConfig['on/tomorrow'] //Changing this will alter what the "The NIghtly On" in the menu links to
