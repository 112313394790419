import styled, { CSSObject } from '@emotion/styled'
import {
    breakpoint,
    getComponentTheme,
    breakpointSwitch,
    calcRem,
    MarginProps,
    PaddingProps,
} from '../../__styling'
import { DividerConfig } from './Luna'

type LunaContainerProps = {
    dividerConfig?: DividerConfig
    hasBeforeAside: boolean
    hasAfterAside: boolean
    asideSide: 'right' | 'left'
    margin?: MarginProps
    padding?: PaddingProps
}

const buildGridTemplate = (...rows: string[]) => `"${rows.join('" "')}"`
const buildGridValues = (...values: string[]) => values.join(' ')

export const LunaContainer = styled.section<LunaContainerProps>(
    ({
        theme,
        hasBeforeAside,
        hasAfterAside,
        asideSide,
        dividerConfig,
        margin,
        padding,
    }) => {
        const lunaTheme = getComponentTheme(theme, 'luna')

        // Default to true.
        const hasBeforeAsideAndAfterAsideDivider =
            dividerConfig?.split?.hasBeforeAsideAndAfterAsideDivider ?? true
        const hasVerticalDivider =
            dividerConfig?.split?.hasVerticalDivider ?? true
        const hasBeforeAsideAndAsideDivider =
            dividerConfig?.column?.hasBeforeAsideAndAsideDivider ?? true
        const hasAsideAndAfterAsideDivider =
            dividerConfig?.column?.hasAsideAndAfterAsideDivider ?? true

        const hasMiddleRow: boolean =
            hasBeforeAside &&
            hasAfterAside &&
            hasBeforeAsideAndAfterAsideDivider

        return [
            {
                display: 'grid',
                width: '100%',
                maxWidth: lunaTheme.maxWidth,
                margin: '0 auto',
                // Ternaries here to hide the appropriate dividers + areas when either before/after does not exist.
                // This is the neatest representation I could come up with.
                // prettier-ignore
                gridTemplateAreas: buildGridTemplate(
                    ...(hasBeforeAside ?   ['beforeAside',
                    ...(hasBeforeAsideAndAsideDivider ? ['columnDivider1'] : [])] : []),
                                            'aside', // Always exists.
                    ...(hasAfterAside  ? [
                    ...(hasAsideAndAfterAsideDivider ? ['columnDivider2'] : []),
                                            'afterAside'] : []),
                ),
                // prettier-ignore
                gridTemplateRows: buildGridValues(
                    ...(hasBeforeAside ?    ['auto',
                    ...(hasBeforeAsideAndAsideDivider ? [lunaTheme.divider.thickness] : []) ] : []),
                                             'auto', // Always exists.
                    ...(hasAfterAside  ? [
                    ...(hasAsideAndAfterAsideDivider ? [lunaTheme.divider.thickness] : []),
                                             'auto'] : []),
                ),
                gridTemplateColumns: 'minmax(0, 1fr)', // https://css-tricks.com/preventing-a-grid-blowout/
                transition: 'gap 0.25s, padding 0.25s',

                [breakpoint('lg')]: {
                    // Yes I know this is god-awful in terms of readability, but I have no idea how to improve it...

                    // Ternaries here to hide the appropriate dividers + areas when either before/after does not exist.
                    // prettier-ignore
                    gridTemplateAreas: asideSide === 'right' ? 
                        buildGridTemplate(
                            ...(hasBeforeAside ? [hasVerticalDivider ? 'beforeAside   splitDivider1 aside' : 'beforeAside   aside'] : []),
                            ...(hasMiddleRow   ? [hasVerticalDivider ? 'splitDivider2 splitDivider1 aside' : 'splitDivider2 aside'] : []),
                            ...(hasAfterAside  ? [hasVerticalDivider ? 'afterAside    splitDivider1 aside' : 'afterAside    aside'] : []),
                        ) : 
                        buildGridTemplate(
                            ...(hasBeforeAside ? [hasVerticalDivider ? 'aside splitDivider1 beforeAside'   : 'aside beforeAside'  ] : []),
                            ...(hasMiddleRow   ? [hasVerticalDivider ? 'aside splitDivider1 splitDivider2' : 'aside splitDivider2'] : []),
                            ...(hasAfterAside  ? [hasVerticalDivider ? 'aside splitDivider1 afterAside'    : 'aside afterAside'   ] : []),
                        ),
                    // prettier-ignore
                    gridTemplateRows: buildGridValues(
                        ...(hasBeforeAside ? ['auto'                     ] : []),
                        ...(hasMiddleRow   ? [lunaTheme.divider.thickness] : []),
                        ...(hasAfterAside  ? ['auto'                     ] : []),
                    ),
                    // prettier-ignore
                    gridTemplateColumns: buildGridValues(...[
                        asideSide === 'right' ? lunaTheme.primary.width : lunaTheme.aside.width,
                        ...(hasVerticalDivider ? [lunaTheme.divider.thickness] : []),
                        asideSide === 'right' ? lunaTheme.aside.width : lunaTheme.primary.width,
                    ]),
                },
            },
            // Build CSS from theme breakpoint variants.
            breakpointSwitch(lunaTheme.breakpointState, ({ padding, gap }) => ({
                padding,
                rowGap: gap.row,
                columnGap: gap.column,
            })),
            margin && breakpointSwitch(margin, (style) => style),
            padding && breakpointSwitch(padding, (style) => style),
        ]
    },
)

const slotStyles: CSSObject = {
    display: 'flex',
    flexDirection: 'column',
}

export const BeforeAsideSlot = styled.div<{ hasMarginTop?: boolean }>(
    ({ theme, hasMarginTop = false }) => {
        return {
            gridArea: 'beforeAside',
            marginTop: hasMarginTop
                ? calcRem(theme.margins.outerMargin)
                : undefined,
            ...slotStyles,
            [breakpoint('sm')]: {
                marginTop: hasMarginTop ? calcRem(40) : undefined,
            },
            [breakpoint('lg')]: {
                marginTop: hasMarginTop ? calcRem(80) : undefined,
            },
        }
    },
)

export const AfterAsideSlot = styled.div({
    gridArea: 'afterAside',
    ...slotStyles,
})

export const AsideSlot = styled.aside<{
    hasMarginTop?: boolean
    hideAsideOnMobile?: boolean
}>(({ hasMarginTop = false, hideAsideOnMobile = false }) => {
    return [
        {
            gridArea: 'aside',
            ...slotStyles,

            [breakpoint('lg')]: {
                marginTop: hasMarginTop ? calcRem(80) : undefined,
            },
        },
        hideAsideOnMobile === true && {
            display: 'none',

            [breakpoint('lg')]: {
                ...slotStyles,
            },
        },
    ]
})

const Divider = styled.span<{ gridArea: string; hideOnMobile?: boolean }>(
    ({ theme, gridArea, hideOnMobile = false }) => {
        const lunaTheme = getComponentTheme(theme, 'luna')

        return [
            {
                gridArea,
                display: 'block',
                background: lunaTheme.divider.background,
            },
            hideOnMobile === true && {
                display: 'none !important',

                [breakpoint('lg')]: {
                    display: 'block',
                },
            },
        ]
    },
)

/** Divider for when the aside is split to the left */
export const SplitModeDivider = styled(Divider)({
    display: 'none',

    [breakpoint('lg')]: {
        display: 'block',
    },
})

/** Divider for when the elements are stacked in a column */
export const ColumnModeDivider = styled(Divider)<{}>({
    display: 'block',

    [breakpoint('lg')]: {
        display: 'none',
    },
})

export const StyledHeading = styled('h1')({
    display: 'none',
    fontSize: calcRem(51),
    fontWeight: 700,
    lineHeight: calcRem(56),
    letterSpacing: calcRem(-2.04),
    paddingBottom: calcRem(16),
    margin: 0,
    [breakpoint('lg')]: {
        display: 'block',
    },
})
