import {
    AppState,
    AuthenticationState,
    DataLayerEventName,
    LogoutEvent,
    NavEvent,
    NavEventArgs,
    NavItem as WebNavItem,
    SubscribeEvent,
    SearchEvent,
    usePageEvents,
    WebLink,
} from '@news-mono/web-common'
import React, { useCallback, useEffect, useRef } from 'react'
import {
    FacebookIcon,
    FeatureToggle,
    InstagramIcon,
    LinkedinIcon,
    useAuthActions,
    XIcon,
} from '@news-mono/component-library'
import {
    QuickLinkContainer,
    StyledActionWrapper,
    StyledButtonText,
    StyledCloseButton,
    StyledIconCross,
    StyledLinkGroup,
    StyledNavBody,
    StyledActionContent,
    StyledNavMain,
    StyledNavWrapper,
    StyledNavWrapperClass,
    StyledSocialGroup,
    StyledUnderActionBar,
    StyledSectionsNav,
    LinkContainer,
    DesktopNavMain,
    StyledTextLink,
    StyledSearchAction,
    StyledTopRightActions,
} from '../TNFlyoutNav/TNFlyoutNav.styled'
import { TheNightlyNavItem } from '../../routing/route-nav-types'
import { useOnClickOutside } from '../../__helpers/on-click-outside'
import { useProduct } from '../../__product/useProduct'
import { TheNightlyStackedLogo } from '../../logos/TheNightlyLogo/TheNightlyStackedLogo'
import { NavItem } from './components/NavItem'
import { NavSection } from './components/NavSection'
import { NavSectionOptions } from './components/NavSectionOptions'
import { TheNightlySection } from '@news-mono/common'
import { RoutableNightlySection } from '@west-australian-newspapers/publication-types'
import { useSelector } from 'react-redux'
import { UserIcon } from '../TheNightlyHeaderActions/assets/UserIcon'
import { YoutubeIcon } from '../../icons/IconNightlySocial/YoutubeIcon'
import { SearchAction } from '../SearchAction/SearchAction'

export interface TNFlyoutNavCommonProps {
    includeSubNav?: boolean
    navLinks: {
        latest: TheNightlyNavItem[]
        'my-account': TheNightlyNavItem[]
        'the-nightly': TheNightlyNavItem[]
        section: Record<RoutableNightlySection, TheNightlyNavItem>
    }
    onEvent: (
        event: NavEvent | SubscribeEvent | LogoutEvent | SearchEvent,
    ) => void
    mainNavOpen: boolean
    closeNavigation: () => void
    startingSelection?: TheNightlySection
}

export function TNFlyoutNav({
    navLinks,
    mainNavOpen,
    closeNavigation,
    onEvent,
    startingSelection,
}: TNFlyoutNavCommonProps) {
    const product = useProduct()
    const { onLoginClick } = useAuthActions()
    const { isLoggedIn } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )
    const inApp = useSelector(
        (state: AppState) => state.render.renditionType === 'app',
    )

    const [impression, setImpression] = React.useState(false)
    const [currentSection, setCurrentSection] =
        React.useState<TheNightlySection | null>(startingSelection ?? null)
    const wrapperRef = useRef<HTMLElement | null>(null)
    const closeButtonRef = useRef<HTMLButtonElement | null>(null)
    const flyoutOptionsRef = useRef<HTMLUListElement | null>(null)

    const handleClose = useCallback(() => {
        setCurrentSection(null)
        if (mainNavOpen) {
            closeNavigation()
        }
    }, [closeNavigation, mainNavOpen])

    const handleTopicClick = (args: NavEventArgs) => {
        onEvent({
            type: DataLayerEventName.navClicked,
            originator: 'FlyoutNav',
            payload: {
                navName: 'Flyout.TheNightly',
                navLocation: 'Header',
                navPos: args.navPos,
                navText: args.navText,
                navLink: args.navLink,
                navPath: args.navParent
                    ? `${args.navParent} > ${args.navText}`
                    : `${args.navText}`,
            },
        })

        closeNavigation()
        setCurrentSection(null)
    }

    const handleSectionClick = ({ section }: TheNightlyNavItem) => {
        if (section === currentSection) {
            setCurrentSection(null)
        } else {
            setCurrentSection(section)
            flyoutOptionsRef?.current?.focus()
        }
    }

    useOnClickOutside(wrapperRef, handleClose, {
        enabled: mainNavOpen,
        filterClasses: [StyledNavWrapperClass],
    })

    // FlyoutNav should only trigger impression once per page load.
    usePageEvents(() => {
        setImpression(true)
    }, ['page-load-complete'])

    // Impressions for analytics.
    useEffect(() => {
        if (mainNavOpen) {
            if (impression) {
                onEvent({
                    type: DataLayerEventName.navAvailable,
                    originator: 'Flyout.TheNightly',
                    payload: {
                        navName: 'Flyout.TheNightly',
                    },
                })
                setImpression(false)
            }
        }
    }, [mainNavOpen, impression, onEvent, product])

    // Set tab focus to the close button when opened.
    useEffect(() => {
        if (mainNavOpen && closeButtonRef.current) {
            closeButtonRef.current.focus()
        }
    }, [mainNavOpen])

    // Close the nav if ESC is hit while anywhere on the page.
    useEffect(() => {
        const closeOnEscapeKey = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                handleClose()
            }
        }

        let escapeListener
        if (mainNavOpen) {
            escapeListener = document.addEventListener(
                'keydown',
                closeOnEscapeKey,
            )
        } else {
            document.removeEventListener('keydown', closeOnEscapeKey)
        }

        return () => {
            document.removeEventListener('keydown', closeOnEscapeKey)
        }
    }, [handleClose, mainNavOpen])

    return (
        <StyledNavWrapper
            role="navigation"
            ref={wrapperRef}
            isOpen={mainNavOpen}
            tabIndex={-1}
        >
            <StyledActionWrapper>
                <StyledActionContent>
                    <StyledCloseButton
                        type="button"
                        onClick={handleClose}
                        ref={closeButtonRef}
                    >
                        <StyledButtonText>
                            Close navigation menu
                        </StyledButtonText>
                        <StyledIconCross />
                    </StyledCloseButton>
                    <StyledTopRightActions>
                        <FeatureToggle
                            feature={'search'}
                            on={() => (
                                <StyledSearchAction>
                                    <SearchAction
                                        isLarge
                                        onSubmit={() => {
                                            handleClose()
                                        }}
                                        onEvent={onEvent}
                                        callLocation="Flyout"
                                    />
                                </StyledSearchAction>
                            )}
                        />
                        {!inApp &&
                            (isLoggedIn ? (
                                <UserIcon show={true} fillColor={'#ffffff'} />
                            ) : (
                                <StyledTextLink
                                    id="action-login-button"
                                    to={'#'}
                                    onClick={() => onLoginClick('flyout-nav')}
                                >
                                    Log in
                                </StyledTextLink>
                            ))}
                    </StyledTopRightActions>
                </StyledActionContent>
            </StyledActionWrapper>
            <StyledUnderActionBar>
                <StyledSocialGroup>
                    <WebLink to={'https://facebook.com/thenightlyau'}>
                        <FacebookIcon />
                    </WebLink>
                    <WebLink
                        to={'https://www.linkedin.com/company/the-nightly/'}
                    >
                        <LinkedinIcon />
                    </WebLink>
                    <WebLink to={'https://www.instagram.com/thenightlyau/'}>
                        <InstagramIcon />
                    </WebLink>
                    <WebLink to={'https://youtube.com/@thenightlyau'}>
                        <YoutubeIcon />
                    </WebLink>
                    <WebLink to={'https://x.com/thenightlyau'}>
                        <XIcon />
                    </WebLink>
                </StyledSocialGroup>
                <StyledNavBody>
                    <StyledNavMain>
                        <QuickLinkContainer>
                            {getLatestMenu(navLinks.latest, handleTopicClick)}
                            {inApp &&
                                isLoggedIn &&
                                getMyAccountMenu(
                                    navLinks['my-account'],
                                    handleTopicClick,
                                )}
                            {getTopicsMenu(
                                navLinks.section,
                                currentSection,
                                handleSectionClick,
                                handleTopicClick,
                            )}
                            {getTheNightlyMenu(
                                navLinks['the-nightly'],
                                handleTopicClick,
                            )}
                        </QuickLinkContainer>
                        <TheNightlyStackedLogo
                            logoHeight={48}
                            logoWidth={170}
                        />
                    </StyledNavMain>

                    {getSectionsMenu(
                        navLinks,
                        currentSection,
                        handleSectionClick,
                        handleTopicClick,
                        flyoutOptionsRef,
                    )}
                </StyledNavBody>
            </StyledUnderActionBar>
        </StyledNavWrapper>
    )
}

function getLatestMenu(
    navLinks: TheNightlyNavItem[],
    handleTopicClick: (args: NavEventArgs) => void,
) {
    return (
        <LinkContainer>
            <p>Latest</p>
            <StyledLinkGroup role="menu">
                {navLinks.map((navItem: WebNavItem, index: number) => (
                    <NavItem
                        webNavItem={navItem}
                        onClick={handleTopicClick}
                        navPos={index}
                        key={index}
                    />
                ))}
            </StyledLinkGroup>
        </LinkContainer>
    )
}

function getMyAccountMenu(
    navLinks: TheNightlyNavItem[],
    handleTopicClick: (args: NavEventArgs) => void,
) {
    return (
        <LinkContainer>
            <p>My Account</p>
            <StyledLinkGroup role="menu">
                {navLinks.map((navItem: WebNavItem, index: number) => (
                    <NavItem
                        webNavItem={navItem}
                        onClick={handleTopicClick}
                        navPos={index}
                        key={index}
                    />
                ))}
            </StyledLinkGroup>
        </LinkContainer>
    )
}

function getTheNightlyMenu(
    navLinks: TheNightlyNavItem[],
    handleTopicClick: (args: NavEventArgs) => void,
) {
    return (
        <LinkContainer>
            <p>The Nightly</p>
            <StyledLinkGroup role="menu">
                {navLinks.map((navItem: WebNavItem, index: number) => (
                    <NavItem
                        webNavItem={navItem}
                        onClick={handleTopicClick}
                        navPos={index}
                        key={index}
                    />
                ))}
            </StyledLinkGroup>
        </LinkContainer>
    )
}

function getTopicsMenu(
    navLinks: Record<RoutableNightlySection, TheNightlyNavItem>,
    currentSection: TheNightlySection | null,
    handleSectionClick: ({ section }: TheNightlyNavItem) => void,
    handleTopicClick: (args: NavEventArgs) => void,
) {
    return (
        <NavSection
            sectionHeadings={Object.values(navLinks)}
            currentSection={currentSection}
            onSectionClick={handleSectionClick}
            onTopicClick={handleTopicClick}
            hideAtBreakpoint={'sm'}
        />
    )
}

function getSectionsMenu(
    navLinks: {
        latest: TheNightlyNavItem[]
        'my-account': TheNightlyNavItem[]
        'the-nightly': TheNightlyNavItem[]
        section: Record<RoutableNightlySection, TheNightlyNavItem>
    },
    currentSection: TheNightlySection | null,
    handleSectionClick: ({ section }: TheNightlyNavItem) => void,
    handleTopicClick: (args: NavEventArgs) => void,
    flyoutOptionsRef: React.MutableRefObject<HTMLUListElement | null>,
) {
    return (
        <StyledSectionsNav>
            <StyledNavMain>
                <NavSection
                    sectionHeadings={Object.values(navLinks.section)}
                    currentSection={currentSection}
                    onSectionClick={handleSectionClick}
                    onTopicClick={handleTopicClick}
                />
            </StyledNavMain>
            {currentSection && currentSection !== 'default' && (
                <DesktopNavMain>
                    <LinkContainer>
                        <p>{navLinks.section[currentSection].name}</p>
                        <NavSectionOptions
                            name={currentSection.toLowerCase()}
                            sectionItems={
                                navLinks.section[currentSection]?.subNavLinks ??
                                []
                            }
                            onClick={handleTopicClick}
                            isOpen={true}
                            ref={flyoutOptionsRef}
                        />
                    </LinkContainer>
                </DesktopNavMain>
            )}
        </StyledSectionsNav>
    )
}
