import {
    AdState,
    AllEvents,
    ComponentServices,
    RouteResolution,
    TogglesReduxState,
} from '@news-mono/web-common'
import { Logger } from 'typescript-log'
import { getInlinePositionedAds } from '../../../templates/Publication/lib/get-inline-positioned-ads'
import {
    MetaTypes,
    PublicationTypes,
} from '../../../templates/Publication/SharedPublication.routing'
import { getInlinePositionedTNRegisterAccountDriver } from '../lib/get-inline-positioned-thenightly-register-account'
import { getInlinePositionedTNNewsletterBanner } from '../lib/get-inline-positioned-thenightly-newsletter'
import { getInlinePositionedOlympicsMedalWidget } from '../lib/get-inline-positioned-olympics-widget'
import { getInlinePositionedTheGameBanner } from '../lib/get-inline-positioned-the-game-banner'
import { getInlinePositionedPollieRaterCta } from '../lib/get-inline-positioned-pollie-rater-banner'

export function getNightlyInlinePositionedContent(props: {
    adState: AdState
    publication: PublicationTypes
    meta: MetaTypes
    log: Logger
    toggles: TogglesReduxState
    onEvent: (event: AllEvents) => void
    section?: string
    services: ComponentServices<RouteResolution>
}) {
    if (!props.publication) {
        return []
    }

    // inline content should not be rendered on gallery publications or events (live blogs)
    if (
        props.publication.kind === 'gallery' ||
        props.publication.kind === 'event' ||
        props.meta.kind === 'gallery' ||
        !props.meta.inlinePublicationContent
    ) {
        return []
    }

    return [
        ...getInlinePositionedAds({
            adState: props.adState,
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedTNRegisterAccountDriver({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedTheGameBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedTNNewsletterBanner({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
            services: props.services,
        }),
        ...getInlinePositionedOlympicsMedalWidget({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
        ...getInlinePositionedPollieRaterCta({
            publication: props.publication,
            inlinePublicationContent: props.meta.inlinePublicationContent,
            onEvent: props.onEvent,
        }),
    ]
}
