import {
    createRegisterableComponent,
    ElectionDefinition,
} from '@news-mono/web-common'
import React from 'react'
import { TNElectorateHeader } from './TNElectorateHeader'

export interface TNElectorateHeaderProps {
    electionDefinition: ElectionDefinition
}

export const TNElectorateHeaderRegistration = createRegisterableComponent(
    'tn-electorate-header',
    (props: TNElectorateHeaderProps, services) => {
        return <TNElectorateHeader {...props} onEvent={services.onEvent} />
    },
)
