import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import { TNElectionSidebarFilter } from './TNElectionSidebarFilter'

export interface TNElectionFilterRegistrationProps {
    isSearchEnabled?: boolean
    isAlphabeticalEnabled?: boolean
    isStateEnabled?: boolean
    topMarginOverride?: number
}

export const TNElectionSidebarFilterRegistration = createRegisterableComponent(
    'tn-election-sidebar-filter',
    (props: TNElectionFilterRegistrationProps, services) => {
        return <TNElectionSidebarFilter {...props} onEvent={services.onEvent} />
    },
)
