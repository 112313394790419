import { TheNightlySection } from '@news-mono/common'
import { IconChevronUpTN, metrics } from '@news-mono/component-library'
import {
    Topic,
    AdTargeting,
    GetRouteAdTargeting,
    PageType,
} from '@news-mono/web-common'
import { layout } from '../../App.routing'
import H from 'history'
import React from 'react'
import { onEditionsConfig } from './onEditionsConfig'
import { billboardLeaderboardMrec } from '../ad-units'

const MAIN_CONTENT_ID = 'main-content'

type createTheNightlyOnLandingPageArgs = {
    baseUrl: string
    topic: Topic
    section: TheNightlySection
    getAdTargeting: GetRouteAdTargeting
    location: H.Location<any>
}

export function createTheNightlyOnLandingPage({
    topic,
    section,
    getAdTargeting,
    baseUrl,
    location,
}: createTheNightlyOnLandingPageArgs): PageType<TheNightlySection> {
    const title = topic.seoTitle || topic.title
    const canonicalUrl = `${baseUrl}${location.pathname}`

    /**
     *Provide a hardcoded list of editions from config
     * @todo replace with a robust data source, eg a curation or topic listing
     */
    const editions = Object.values(onEditionsConfig)

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        'home',
        section,
        topic,
    )
    const adTargeting: AdTargeting = {
        pageId: topic.title,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    return {
        kind: 'page',
        heading: 'The Nightly On',
        pageType: 'topic',
        hideHeading: true,
        refreshOnInactive: false,
        pageMeta: {
            title,
            description:
                topic.seoDescription || `The latest in ${topic.seoTitle}`,
            meta: [
                {
                    property: 'og:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:title',
                    content: `${topic.seoTitle || topic.title} | The Nightly`,
                },
                {
                    property: 'twitter:description',
                    content:
                        topic.seoDescription ||
                        `The latest in ${topic.seoTitle}`,
                },
                {
                    property: 'og:image:width',
                    content: '1200',
                },
                {
                    property: 'og:image:height',
                    content: '630',
                },
            ],
            link: [{ rel: 'canonical', href: canonicalUrl }],
        },
        adTargeting,
        section,
        compositions: [
            layout.composition({
                type: 'thor',
                props: {
                    containerWidth: '100%',
                    horizontalGutters: false,
                    verticalGutters: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                horizontalSpacingBreakpoint: 'sm',
                                horizontalSpacing: 'outerMargin',
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'md',
                                            containerWidth:
                                                metrics.thenightly.siteMetrics
                                                    .mainContentWidth,
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'nightly-special-edition',
                                                    props: {
                                                        ...onEditionsConfig[
                                                            'on/tomorrow'
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            flex: {
                                                default: {
                                                    hasBeforeAfterMargins: true,
                                                    flexDirection: 'column',
                                                    gap: '40px',
                                                },
                                                lg: {
                                                    hasBeforeAfterMargins: true,
                                                    flexDirection: 'column',
                                                    gap: '80px',
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                ...billboardLeaderboardMrec(
                                                    'one',
                                                ),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                flex: {
                                    default: {
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        gap: '40px',
                                    },
                                    lg: {
                                        gap: '80px',
                                    },
                                },
                                containerWidth: '100%',
                            },
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'collection-data-context',
                                        props: {
                                            contextId: MAIN_CONTENT_ID,
                                            dataDefinitionArgs: {
                                                type: 'edition',
                                                page: 1,
                                                throw404OnNoResults: true,
                                                pageSize: 20,
                                            },
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'nightly-previous-special-editions',
                                                    props: {
                                                        editions:
                                                            editions.reverse(),
                                                    },
                                                }),
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        horizontalSpacingBreakpoint:
                                                            'sm',
                                                        horizontalSpacing:
                                                            'outerMargin',
                                                        flex: {
                                                            default: {
                                                                flexDirection:
                                                                    'column',
                                                                gap: '40px',
                                                            },
                                                            lg: {
                                                                gap: '32px',
                                                            },
                                                        },
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            // @todo: add pagination when we have more than 2 articles :upsidedown:
                                                            // layout.component({
                                                            //     type: 'pagination',
                                                            //     props: {
                                                            //         contextId:
                                                            //             MAIN_CONTENT_ID,
                                                            //     },
                                                            // }),
                                                            layout.component({
                                                                type: 'nightly-button',
                                                                props: {
                                                                    variant:
                                                                        'default',
                                                                    text: 'BACK TO TOP',
                                                                    color: 'primary',
                                                                    fill: 'text',
                                                                    layout: {
                                                                        alignSelf:
                                                                            'center',
                                                                    },
                                                                    action: {
                                                                        type: 'button',
                                                                        onClick:
                                                                            () =>
                                                                                window.scrollTo(
                                                                                    {
                                                                                        top: 0,
                                                                                    },
                                                                                ),
                                                                    },
                                                                    icon: {
                                                                        iconPosition:
                                                                            'right',
                                                                        IconElement:
                                                                            (
                                                                                <IconChevronUpTN />
                                                                            ),
                                                                    },
                                                                },
                                                            }),
                                                        ],
                                                    },
                                                }),
                                                ...billboardLeaderboardMrec(
                                                    'two',
                                                ),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}
