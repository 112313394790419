import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    fonts,
    metrics,
} from '../../../__styling'

export const StyledEditionsPage = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(40),
    alignItems: 'center',

    [breakpoint('sm')]: {
        gap: calcRem(80),
        padding: 0,
    },
})

export const StyledEditionsSection = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: calcRem(16),
    maxWidth: metrics.thenightly.siteMetrics.mainContentWidth,
    padding: calcRem(0, 16),

    [breakpoint('sm')]: {
        padding: 0,
    },
})

export const StyledEditionsHeading = styled.div({
    fontFamily: fonts.thenightly.sansSerif,

    h2: {
        margin: 0,
        padding: 0,
        fontSize: calcRem(40),
        fontWeight: 700,
        letterSpacing: '-1.6px',
        textTransform: 'capitalize',
        lineHeight: calcRem(48),
    },

    [breakpoint('sm')]: {
        h2: {
            fontSize: calcRem(51),
            lineHeight: calcRem(56),
        },
    },
})

export const StyledEditionList = styled.ul({
    display: 'grid',
    padding: 0,
    margin: 0,
    rowGap: calcRem(32),
    columnGap: calcRem(32),
    justifyContent: 'center',
    transition: 'gap 0.25s',

    [breakpointMax('sm')]: {
        rowGap: calcRem(32),
        columnGap: calcRem(16),
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoint('sm')]: {
        rowGap: calcRem(40),
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoint('md')]: {
        rowGap: calcRem(48),
        gridTemplateColumns: '1fr 1fr',
    },
    [breakpoint('lg')]: {
        gridTemplateColumns: '1fr 1fr',
    },
})
