import {
    AllEvents,
    AppState,
    AuthenticationState,
    DataLayerEventName,
    useFeature,
    Features,
} from '@news-mono/web-common'
import React from 'react'
import { ImpressionAvailable } from '../../__helpers/impression-available-helper'
import { StyledCreateAccountInArticleContainer } from './components/StyledCreateAccountInArticleContainer'
import { tokens } from '@news-mono/design-tokens'
import {
    StyledContainerWithBorderTopAndBottom,
    StyledWardenParagraph,
    StyledWardenSubTitle,
    StyledWardenButton,
} from '../../user-registration/warden'
import { useAuthActions } from '../../user-registration'
import { useSelector } from 'react-redux'

export interface TNCreateAccountBannerProps {
    feature: Features
    onEvent: (event: AllEvents) => void
    heading: string
}

export const TNCreateAccountBanner: React.FC<TNCreateAccountBannerProps> = ({
    feature,
    onEvent,
    heading,
}) => {
    const { onRegisterClick, onLoginClick } = useAuthActions()
    const authentication = useSelector<AppState, AuthenticationState>(
        ({ authentication }) => authentication,
    )
    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'
    const isFeatureEnabled = useFeature(feature)
    const isLoggedIn = authentication.isLoggedIn

    // Do our logged in and feature enabled state checks first, if it fails return an empty container.
    if (isLoggedIn || !isFeatureEnabled) {
        return <></>
    }

    return (
        <ImpressionAvailable
            loading={false}
            available={() => {
                onEvent({
                    type: DataLayerEventName.genericArticleBannerViewed,
                    originator: `TNCreateAccountBanner`,
                    payload: {
                        heading,
                    },
                })
            }}
        >
            {() => (
                <StyledContainerWithBorderTopAndBottom
                    color={tokens.thenightly.colors.palette.neutral[20]}
                    addMargin={true}
                    addPadding={false}
                >
                    <StyledCreateAccountInArticleContainer>
                        <StyledWardenSubTitle isAdaptiveSize={true}>
                            Create your free account.
                        </StyledWardenSubTitle>
                        <StyledWardenParagraph>
                            Australia's best journalism for FREE, including our
                            weeknight digital edition, puzzles, newsletters and
                            comments.
                        </StyledWardenParagraph>
                        <StyledWardenButton
                            onClick={() =>
                                onRegisterClick('article-inline', renditionType)
                            }
                            backgroundColors={{
                                default:
                                    tokens.thenightly.colors.palette.utility
                                        .error.default,
                                hover: tokens.thenightly.colors.palette.utility
                                    .error.hover,
                            }}
                            roundEdges={true}
                            useWidePadding={true}
                        >
                            CREATE FREE ACCOUNT
                        </StyledWardenButton>
                        <StyledWardenParagraph>
                            Already have an account?&nbsp;
                            <a onClick={() => onLoginClick('article-inline')}>
                                Log in here.
                            </a>
                        </StyledWardenParagraph>
                    </StyledCreateAccountInArticleContainer>
                </StyledContainerWithBorderTopAndBottom>
            )}
        </ImpressionAvailable>
    )
}

export default TNCreateAccountBanner
