import { TheNightlySection } from '@news-mono/common'
import {
    calcRem,
    metrics,
    theNightlyTextOnlySectionHeaderOverride,
} from '@news-mono/component-library'
import {
    getSocialImageMeta,
    PageType,
    GetRouteAdTargeting,
    getElectionAdPageKindFromElectionId,
    ElectionDefinition,
} from '@news-mono/web-common'
import { layout } from '../../../App.routing'
import { getYear } from 'date-fns'
import { wrapWithElectionContext } from '../routes'

type CreateElectionPageArgs = {
    getAdTargeting: GetRouteAdTargeting
    electionDefinition: ElectionDefinition
}

export const createElectionPartyTotalPage = ({
    getAdTargeting,
    electionDefinition,
}: CreateElectionPageArgs): PageType<TheNightlySection> => {
    const meta = electionDefinition.meta
    const isFederal =
        electionDefinition.electionData?.config?.electionType.toLowerCase() ===
        'federal'
    const electionState =
        electionDefinition.electionData?.config?.state?.toUpperCase()
    const electionYear = getYear(
        new Date(electionDefinition.electionData?.config?.electionDate ?? ''),
    )

    let defaultTitle
    let defaultDescription

    if (isFederal) {
        defaultTitle = `Party results Australian election ${electionYear}`
        defaultDescription = `All the party results for the ${electionYear} Australian federal election.`
    } else {
        defaultTitle = `Party results ${electionState} State election ${electionYear}`
        defaultDescription = `All the party results for the ${electionYear} ${electionState} State election.`
    }

    const seoTitle = meta.partyTotals?.seoTitle ?? defaultTitle
    const seoDescription =
        meta.partyTotals?.seoDescription ?? defaultDescription

    const electionPageKind = getElectionAdPageKindFromElectionId(
        electionDefinition.electionId,
    )

    const adTargeting = getAdTargeting(
        electionPageKind ?? 'home',
        'default',
        electionDefinition.electionId,
    )

    // as per https://swmdigital-wa.slack.com/archives/C07TZDGREDA/p1741412941432569?thread_ts=1741411065.813789&cid=C07TZDGREDA
    // not sure if this is the best way to do it, so we should fix it up later
    adTargeting.ssAdUnits = adTargeting.ssAdUnits
        .flatMap((adUnit) => adUnit.split('/'))
        .map((adUnit) =>
            adUnit === 'WAelection2025' ? 'WA election 2025' : adUnit,
        )

    return {
        kind: 'page',
        heading: seoTitle,
        pageType: 'static',
        section: 'default',
        hideHeading: true,
        adTargeting,
        pageMeta: {
            title: seoTitle,
            description: seoDescription,
            link: meta.canonical
                ? [
                      {
                          rel: 'canonical',
                          href: `${meta.canonical}politics/${electionDefinition.electionId}/party-totals`,
                      },
                  ]
                : undefined,
            meta: meta.partyTotals?.socialImage
                ? getSocialImageMeta(
                      meta.partyTotals.socialImage,
                      '2400',
                      '1260',
                  )
                : undefined,
        },
        socialMeta: {
            title: seoTitle,
            description: seoDescription,
        },
        additionalPageProperties: {
            hasTopGutter: false,
        },
        compositions: wrapWithElectionContext([
            layout.nestedComposition({
                type: 'box',
                props: {
                    containerWidth:
                        metrics.thewest.siteMetrics.mainContentWidth,
                    fullWidth: true,
                    flex: {
                        default: {
                            flexDirection: 'column',
                        },
                    },
                    verticalGutters: ['unset', 'unset'],
                    hasTopSpacing: false,
                },
                contentAreas: {
                    main: [
                        layout.nestedComposition({
                            type: 'box',
                            props: {},
                            contentAreas: {
                                main: [
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'unset',
                                            breakpoints: {
                                                lg: {
                                                    verticalSpacing: 'unset',
                                                },
                                            },
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'election-head-to-head-seat-count-widget',
                                                    props: {
                                                        electionDefinition,
                                                    },
                                                }),
                                                layout.component({
                                                    type: 'election-navigation',
                                                    props: {
                                                        electionDefinition,
                                                        activePage:
                                                            'party-totals',
                                                        headingLevel: 'h2',
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'luna',
                            props: {
                                hasAfterAside: false,
                                hideAsideOnMobile: true,
                                padding: {
                                    default: {
                                        padding: calcRem(0, 24),
                                    },
                                    lg: {
                                        padding: 0,
                                    },
                                },
                            },
                            contentAreas: {
                                beforeAside: [
                                    layout.nestedComposition({
                                        type: 'component-overrider',
                                        props: {
                                            override: () =>
                                                theNightlyTextOnlySectionHeaderOverride,
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.component({
                                                    type: 'section-header',
                                                    props: {
                                                        margin: {
                                                            default: {
                                                                marginTop: 40,
                                                            },
                                                            lg: {
                                                                marginTop: 80,
                                                            },
                                                        },
                                                        heading: 'Party Totals',
                                                        isStandalonePage: true,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    layout.component({
                                        type: 'election-party-totals',
                                        props: {
                                            electionDefinition,
                                            description: seoDescription,
                                        },
                                    }),
                                ],

                                aside: [
                                    layout.component({
                                        type: 'tn-election-sidebar-filter',
                                        props: {
                                            topMarginOverride: 80,
                                            state: true,
                                            alphabetical: false,
                                            search: false,
                                        },
                                    }),
                                    layout.component({
                                        type: 'ad-unit',
                                        props: {
                                            noticePosition: 'above-center',
                                            padding: [
                                                0,
                                                0,
                                                metrics.thewest.margins.md,
                                                0,
                                            ],
                                            slot: {
                                                id: 'sidebar-top',
                                                size: 'desktopMrecHalfPage',
                                                pageType: 'static',
                                            },
                                            adType: 'inline',
                                        },
                                    }),
                                ],
                                afterAside: [],
                            },
                        }),
                    ],
                },
            }),
        ]),
    }
}
