import { createRegisterableComponent } from '@news-mono/web-common'
import React from 'react'
import {
    NightlyPreviousSpecialEditionsProps,
    NightlyPreviousSpecialEditions,
} from './PreviousSpecialEditions'

export const NightlyPreviousSpecialEditionsRegistration =
    createRegisterableComponent(
        'nightly-previous-special-editions',
        (props: NightlyPreviousSpecialEditionsProps) => {
            return <NightlyPreviousSpecialEditions {...props} />
        },
    )
