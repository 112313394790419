import {
    ContentDataTypes,
    LoadedElectionDefinition,
    UnloadedElectionDefinition,
} from '@news-mono/web-common'
import { ElectionDataResponse } from '@west-australian-newspapers/election-api-types'
import { ElectionMeta } from '@west-australian-newspapers/publication-types'
import deepmerge from 'deepmerge'
import { layout } from '../../App.routing'
import { CheckedComponentInformation } from 'json-react-layouts'

const getElectionMeta = (wanElectionId: string): ElectionMeta => {
    if (wanElectionId.startsWith('federal')) {
        return {
            canonical: 'https://thenightly.com.au/',
            updates: {
                socialImage: require('./assets/federal-election-2025/UpdatesSocial.jpg'),
            },
            electorates: {
                socialImage: require('./assets/federal-election-2025/ElectorateResultsSocial.jpg'),
                seoTitle: 'Federal election 2025 electorate results',
                seoDescription:
                    'Full Australian election 2025 results: See all the votes and how every seat across Australia was won in the 2025 Federal election.',
            },
            partyTotals: {
                socialImage: require('./assets/federal-election-2025/PartyTotalsSocial.jpg'),
                seoTitle: 'Party results Australian election 2025',
                seoDescription:
                    'All the party results for the 2025 Australian Federal election. See how the Australian Labor Party, the Liberal Party, the Nationals, the Greens and the independents all stacked up.',
            },
            pollieRater: {
                socialImage: require('./assets/federal-election-2025/UpdatesSocial.jpg'), // Not defined?
                seoTitle:
                    'Pollie Rater: Rate your Federal politicians ahead of the election',
                seoDescription: `Pollie Rater lets you have your say on Federal politicians, candidates and cabinet ministers ahead of the election.`,
            },
        }
    } else if (wanElectionId.startsWith('wa')) {
        return {
            updates: {
                socialImage: require('./assets/wa-election-2025/UpdatesSocial.jpg'),
            },
            electorates: {
                socialImage: require('./assets/wa-election-2025/ElectoratesSocial.jpg'),
                seoTitle: 'WA election 2025 electorate results',
                seoDescription:
                    'Full WA election 2025 results: See all the votes and how every seat across Western Australia was won in the 2025 State election.',
            },
            partyTotals: {
                socialImage: require('./assets/wa-election-2025/PartyTotalsSocial.jpg'),
                seoTitle: 'Party results WA State election 2025',
                seoDescription:
                    'All the party results for the 2025 Western Australian State election. See how the Australian Labor Party, the Liberal Party, the Nationals, the Greens and the independents all stacked up in WA.',
            },
        }
    }

    return {}
}

const getElectionLogo = (wanElectionId: string) => {
    // Split it on the '-' character
    const splitElectionId = wanElectionId.split('-')
    const [target, _, year] = splitElectionId

    // If targeting federal Election, we know the file path
    if (target === 'federal') {
        return {
            mobile: require(`./assets/federal-election-${year}/LogoMobile.svg`),
            desktop: require(`./assets/federal-election-${year}/LogoDesktop.svg`),
            alt: `${year} Federal Election`,
        }
    } else {
        return {
            mobile: require(`./assets/${target}-election-${year}/LogoMobile.svg`),
            desktop: require(`./assets/${target}-election-${year}/LogoDesktop.svg`),
            alt: `${year} ${target.toUpperCase()} Election`,
        }
    }
}

export const getLoadedElectionDefinition = (
    wanElectionId: string,
    electionData: ElectionDataResponse,
    meta: ElectionMeta,
): LoadedElectionDefinition => {
    return {
        electionId: wanElectionId,
        electionData,
        logo: getElectionLogo(wanElectionId),
        meta: deepmerge(getElectionMeta(wanElectionId), meta),
    }
}
export const getUnloadedElectionDefinition = (
    wanElectionId: string,
): UnloadedElectionDefinition => {
    return {
        electionData: undefined,
        electionId: wanElectionId,
        logo: getElectionLogo(wanElectionId),
        meta: getElectionMeta(wanElectionId),
    }
}

const MAIN_CONTENT_ID = 'main-content'

export const wrapWithElectionContext = (
    content: CheckedComponentInformation[],
    collectionContextDataDefinition?: ContentDataTypes,
) => {
    const childrenContent = collectionContextDataDefinition
        ? [
              layout.nestedComposition({
                  type: 'collection-data-context',
                  props: {
                      contextId: MAIN_CONTENT_ID,
                      dataDefinitionArgs: collectionContextDataDefinition,
                  },
                  contentAreas: {
                      children: content,
                  },
              }),
          ]
        : content

    return [
        layout.composition({
            type: 'election-filter-data-context',
            props: {},
            contentAreas: {
                children: childrenContent,
            },
        }),
    ]
}

export const wrapWithElectionContextNested = (
    content: CheckedComponentInformation[],
    collectionContextDataDefinition?: ContentDataTypes,
) => {
    const childrenContent = collectionContextDataDefinition
        ? [
              layout.nestedComposition({
                  type: 'collection-data-context',
                  props: {
                      contextId: MAIN_CONTENT_ID,
                      dataDefinitionArgs: collectionContextDataDefinition,
                  },
                  contentAreas: {
                      children: content,
                  },
              }),
          ]
        : content

    return [
        layout.nestedComposition({
            type: 'election-filter-data-context',
            props: {},
            contentAreas: {
                children: childrenContent,
            },
        }),
    ]
}
