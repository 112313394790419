import React from 'react'
import { SpecialEditionProps } from '../SpecialEdition/SpecialEdition'
import {
    StyledEditionsPage,
    StyledEditionsSection,
    StyledEditionsHeading,
    StyledEditionList,
} from './PreviousSpecialEditions.styled'
import { SpecialEditionItem } from './components/EditionItem'

export interface NightlyPreviousSpecialEditionsProps {
    title?: string
    editions: SpecialEditionProps[]
}

export const NightlyPreviousSpecialEditions: React.FC<NightlyPreviousSpecialEditionsProps> =
    ({ editions, title = 'Latest Nightly On Editions' }) => {
        return (
            <StyledEditionsPage>
                <StyledEditionsSection>
                    <StyledEditionsHeading>
                        {editions.length > 0 && <h2>{title}</h2>}
                    </StyledEditionsHeading>

                    <StyledEditionList>
                        {editions.map((edition, idx) => (
                            <SpecialEditionItem
                                title={edition.title}
                                teaser={edition.teaser}
                                link={edition.link}
                                imageURL={edition.imageURL}
                                lazyloadImages={edition.lazyloadImages}
                                key={idx}
                                alt={edition.alt}
                                publicationDate={edition.publicationDate}
                            />
                        ))}
                    </StyledEditionList>
                </StyledEditionsSection>
            </StyledEditionsPage>
        )
    }
