import React, { ReactElement } from 'react'
import { MarginProps, PaddingProps } from '../../__styling'
import {
    AsideSlot,
    LunaContainer,
    BeforeAsideSlot,
    AfterAsideSlot,
    ColumnModeDivider,
    SplitModeDivider,
    StyledHeading,
} from './Luna.styled'

export type DividerConfig = {
    /** Dividers when in column-layout. */
    column?: {
        /** The divider between beforeAside and Aside. */
        hasBeforeAsideAndAsideDivider?: boolean
        /** The divider between Aside and afterAside. */
        hasAsideAndAfterAsideDivider?: boolean
    }
    /** Dividers when in split-layout. */
    split?: {
        /** The divider between Aside and the content. */
        hasVerticalDivider?: boolean
        /** The divider between beforeAside and afterAside. */
        hasBeforeAsideAndAfterAsideDivider?: boolean
    }
}

export type LunaProps = {
    content: {
        beforeAside: ReactElement
        aside: ReactElement
        afterAside: ReactElement
    }
    hasBeforeAside?: boolean
    hasAfterAside?: boolean
    asideSide?: 'right' | 'left'
    dividerConfig?: DividerConfig
    hasMarginTop?: boolean
    heading?: string //added in order to fulfil election designs
    margin?: MarginProps
    padding?: PaddingProps
    hideAsideOnMobile?: boolean
}

export const Luna = ({
    content,
    hasBeforeAside = true,
    hasAfterAside = true,
    asideSide,
    dividerConfig,
    hasMarginTop = false,
    heading,
    margin,
    padding,
    hideAsideOnMobile = false,
}: LunaProps) => {
    const hasBeforeAsideAndAfterAsideDivider =
        dividerConfig?.split?.hasBeforeAsideAndAfterAsideDivider ?? true
    const hasVerticalDivider = dividerConfig?.split?.hasVerticalDivider ?? true
    const hasBeforeAsideAndAsideDivider =
        dividerConfig?.column?.hasBeforeAsideAndAsideDivider ?? true
    const hasAsideAndAfterAsideDivider =
        dividerConfig?.column?.hasAsideAndAfterAsideDivider ?? true

    return (
        <div>
            {heading && <StyledHeading>{heading}</StyledHeading>}
            <LunaContainer
                dividerConfig={dividerConfig}
                hasBeforeAside={hasBeforeAside}
                hasAfterAside={hasAfterAside}
                asideSide={asideSide ?? 'left'}
                margin={margin}
                padding={padding}
            >
                {hasBeforeAside && (
                    <>
                        <BeforeAsideSlot hasMarginTop={hasMarginTop}>
                            {content.beforeAside}
                        </BeforeAsideSlot>
                        {hasBeforeAsideAndAsideDivider && (
                            <ColumnModeDivider
                                gridArea="columnDivider1"
                                hideOnMobile={hideAsideOnMobile}
                            />
                        )}
                    </>
                )}
                <AsideSlot
                    hasMarginTop={hasMarginTop}
                    hideAsideOnMobile={hideAsideOnMobile}
                >
                    {content.aside}
                </AsideSlot>
                {hasAfterAside && (
                    <>
                        {hasAsideAndAfterAsideDivider && (
                            <ColumnModeDivider gridArea="columnDivider2" />
                        )}
                        <AfterAsideSlot>{content.afterAside}</AfterAsideSlot>
                    </>
                )}
                {hasVerticalDivider && (
                    <SplitModeDivider gridArea="splitDivider1" />
                )}
                {hasBeforeAside &&
                    hasAfterAside &&
                    hasBeforeAsideAndAfterAsideDivider && (
                        <SplitModeDivider gridArea="splitDivider2" />
                    )}
            </LunaContainer>
        </div>
    )
}
