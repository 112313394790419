import styled from '@emotion/styled'
import {
    breakpoint,
    breakpointMax,
    calcRem,
    colors,
    metrics,
} from '@news-mono/component-library'
import { AppState } from '@news-mono/web-common'
import { useSelector } from 'react-redux'

export interface Props {
    isAmpRendered?: boolean
}

export const PageWrapper = styled.div({
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    margin: '0 auto',
})

interface PageContentProps {
    hasTopGutter?: boolean
    hasBottomGutter?: boolean
}

export const PageContent = styled.div<PageContentProps>(
    ({ theme, hasTopGutter, hasBottomGutter }) =>
        theme.kind === 'thenightly' && {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',

            paddingTop: hasTopGutter ? calcRem(40) : 0,
            paddingBottom: hasBottomGutter ? calcRem(40) : 0,

            [breakpoint('sm')]: {
                gap: calcRem(64),
                paddingTop: hasTopGutter ? calcRem(48) : 0,
                paddingBottom: hasBottomGutter ? calcRem(48) : 0,
            },

            [breakpoint('lg')]: {
                gap: calcRem(80),
                paddingTop: hasTopGutter ? calcRem(80) : 0,
                paddingBottom: hasBottomGutter ? calcRem(80) : 0,
            },

            transition: 'padding 0.25s, gap 0.25s',
        },
)

export const StyledAmpContent = styled.div<Props>({
    width: '100%',
    maxWidth: metrics.thewest.ampContentWidth,
    padding: calcRem(metrics.thewest.margins.md),
    margin: '0 auto',
})

export const StyledFooterCell = styled.div(() => {
    const { isLive } = useSelector((state: AppState) => state.theWestLive)
    // DPT-1778 offset for west live based on scheduling
    const radioOffset = isLive ? 69 : undefined
    const stickyFooterAdUnitHeight = 60

    return {
        backgroundColor: colors.thewest.greyDolphin,
        paddingBottom: radioOffset && calcRem(radioOffset),
        // DPT-856: Spacing for sticky footer ad-unit
        [breakpointMax('sm')]: {
            paddingBottom: radioOffset
                ? calcRem(radioOffset + stickyFooterAdUnitHeight)
                : calcRem(stickyFooterAdUnitHeight),
        },
    }
})

export const StyledNestedGrid = styled.div({
    margin: '0 auto',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '100%',
})

export const StyledNestedCell = styled.div({
    display: 'block',
    width: '100%',
    boxSizing: 'border-box',
    maxWidth: metrics.thewest.siteMetrics.mainContentWidth,
    padding: `0 ${metrics.thewest.margins.outerMargin}px`,
    margin: '0 auto',
})
