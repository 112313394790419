import React from 'react'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'
import {
    StyledNightlyEditionContainer,
    StyledNightlyEditionHeading,
    StyledNightlyEditionTextContainer,
    StyledNightlyEditionSubHeading,
    StyledNightlyEditionTeaser,
} from '../SpecificEdition/NightlySpecificEdition.styled'
import {
    HeaderContainer,
    StyledDivider,
    StyledNightlyEditionButtonContainerLeft,
    StyledNightlyEditionContentHome,
    StyledNightlyEditionPictureRight,
    StyledNightlyEditionTextContainerLeft,
} from './SpecialEditionHome.styled'
import { HeaderPrefix } from '../SpecialEdition/SpecialEdition.styled'
import { WebLink } from '@news-mono/web-common'

export interface SpecialEditionHomeProps {
    title: string
    teaser: string
    link: string
    imageURL: string
    lazyloadImages?: boolean
}

export const SpecialEditionHome = ({
    title,
    teaser,
    link,
    imageURL,
    lazyloadImages,
}: SpecialEditionHomeProps) => {
    return (
        <>
            <StyledDivider />
            <StyledNightlyEditionContainer style={{ paddingBottom: 0 }}>
                <StyledNightlyEditionContentHome>
                    <HeaderContainer>
                        <HeaderPrefix>The Nightly On</HeaderPrefix>
                        <StyledNightlyEditionHeading>
                            {title} Edition
                        </StyledNightlyEditionHeading>
                    </HeaderContainer>
                    <StyledNightlyEditionTextContainerLeft>
                        <StyledNightlyEditionSubHeading>
                            In this Edition
                        </StyledNightlyEditionSubHeading>
                        <StyledNightlyEditionTeaser>
                            {teaser}
                        </StyledNightlyEditionTeaser>
                    </StyledNightlyEditionTextContainerLeft>
                    <StyledNightlyEditionButtonContainerLeft>
                        <NightlyButton
                            variant={'default'}
                            text={`${title} Edition`}
                            action={{
                                type: 'link',
                                to: link,
                            }}
                            color={'primary'}
                            fill={'filled'}
                            layout={{
                                shouldGrow: true,
                                alignSelf: 'stretch',
                            }}
                        />
                        <NightlyButton
                            variant={'default'}
                            text={'More Articles'}
                            action={{
                                type: 'link',
                                to: `/on/${title
                                    .toLowerCase()
                                    .replace(' ', '-')}`,
                            }}
                            color={'primary'}
                            fill={'outline'}
                            layout={{
                                shouldGrow: true,
                                alignSelf: 'stretch',
                            }}
                        />
                    </StyledNightlyEditionButtonContainerLeft>
                    <StyledNightlyEditionTextContainer></StyledNightlyEditionTextContainer>
                    <StyledNightlyEditionPictureRight>
                        <WebLink to={link}>
                            <img
                                loading={lazyloadImages ? 'lazy' : undefined}
                                src={imageURL}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null
                                    currentTarget.src = require('../../ePaper/assets/paper.jpg')
                                }}
                                alt={'The Nightly Special Edition'}
                            />
                        </WebLink>
                    </StyledNightlyEditionPictureRight>
                </StyledNightlyEditionContentHome>
            </StyledNightlyEditionContainer>
        </>
    )
}
