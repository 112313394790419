import { TheNightlySection } from '@news-mono/common'
import {
    getCoralStoryId,
    getInlineEventAds,
    inlineArticleAds,
    sectionThemeOverrider,
    theNightlySmallSectionHeaderOverride,
    metrics,
    InlineContentTypes,
    InlineTNRegisterAccount,
    IconChevronUpTN,
    teadsAdSlotID,
} from '@news-mono/component-library'
import {
    AdTargeting,
    BaseClientConfig,
    Features,
    GetRouteAdTargeting,
    PageType,
    Product,
    publicationAdKind,
    publicationAdPageType,
    RenderTarget,
    TogglesReduxState,
} from '@news-mono/web-common'
import queryString from 'query-string'
import {
    ArticleLikeV4DTO,
    PageMetaDTO,
    PageMetaLinkDTO,
} from '@west-australian-newspapers/publication-types'
import { layout } from '../../../App.routing'
import { getNoIndexMeta } from './allowList'
import {
    isFeatureEnabled,
    toFeatureState,
} from '@etrigan/feature-toggles-client'
import H from 'history'
import { latestEditionSegment } from '../../home/get-editions-segment'
import { sectionNavItems, subTopicNavItems } from '../../../navigation'
import { cardList } from '../../home/get-cardList-segment'
import { CheckedComponentInformation } from 'json-react-layouts'
import React from 'react'
import { getPaginatedCanonicalUrl, parsePageParam } from '../../helpers'
import { latestOnEdition } from '../../thenightly-on/onEditionsConfig'

const MAX_LIVE_BLOG_PAGES = 100
const LIVE_BLOG_PAGE_SIZE = 10

// Used for Quicklinks
export const publicationPageType = 'publication'

export const createArticleRoute = ({
    article,
    config,
    section,
    location,
    getAdTargeting,
    pageMeta,
    renderTarget,
    toggles,
}: {
    article: ArticleLikeV4DTO
    config: BaseClientConfig
    section: TheNightlySection
    location: H.Location<any>
    getAdTargeting: GetRouteAdTargeting
    renderTarget: RenderTarget
    pageMeta?: PageMetaDTO
    toggles?: TogglesReduxState
}): PageType<TheNightlySection> => {
    const featureState = toggles && toFeatureState(toggles)

    const inApp = renderTarget === 'app'

    const { adUnitPath, ssAdUnits, topics } = getAdTargeting(
        publicationAdKind[article.kind],
        section,
        article.topics.primary,
        ...article.topics.secondary,
    )

    const adTargeting: AdTargeting = {
        pageId: article.slug,
        adUnitPath,
        ssAdUnits,
        topics,
    }

    const isFeature = (feature: string | undefined) =>
        (featureState &&
            feature !== undefined &&
            isFeatureEnabled(featureState, feature, false)) ??
        false

    const standardInlineArticleAdsEnabled = isFeature('tn-article-body-ads')
    const enableExtraInlineArticleAds = standardInlineArticleAdsEnabled

    const enableTeadsAds = isFeature('teads-article-ads')
    const enableTheGameAflPromoDriver =
        article.topics.primary.id.includes('afl') &&
        isFeature('tn-article-driver-the-game-afl')
    const enablePollyRaterBanner = isFeature('pollie-rater-drivers')

    const extraInlineArticleAds = [
        inlineArticleAds({
            insertAfter: 14,
            noticePosition: 'below-center',
            hiddenUntilLoaded: false,
            mastHead: 'thenightly',
            slotID: 'article-mrec-one',
            size: enableExtraInlineArticleAds
                ? 'leaderboardAbove768MrecBelow'
                : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
            lazyLoadingDistance: 0,
        }),
        inlineArticleAds({
            insertAfter: 23,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thenightly',
            slotID: 'article-mrec-two',
            size: enableExtraInlineArticleAds
                ? 'leaderboardAbove768MrecBelow'
                : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
            lazyLoadingDistance: 0,
        }),
        inlineArticleAds({
            insertAfter: 32,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thenightly',
            slotID: 'article-mrec-three',
            size: enableExtraInlineArticleAds
                ? 'leaderboardAbove768MrecBelow'
                : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
            lazyLoadingDistance: 0,
        }),
        inlineArticleAds({
            insertAfter: 41,
            noticePosition: 'below-center',
            hiddenUntilLoaded: true,
            mastHead: 'thenightly',
            slotID: 'article-mrec-four',
            size: enableExtraInlineArticleAds
                ? 'leaderboardAbove768MrecBelow'
                : 'below768Mrec',
            pageType: publicationAdPageType[article.kind],
            lazyLoadingDistance: 0,
        }),
        ...(enableTeadsAds
            ? [
                  inlineArticleAds({
                      insertAfter: 5,
                      noticePosition: 'below-center',
                      hiddenUntilLoaded: false,
                      mastHead: 'thenightly',
                      slotID: teadsAdSlotID,
                      size: 'teads',
                      pageType: publicationAdPageType[article.kind],
                  }),
              ]
            : []),
    ]

    const standardInlineArticleAds = (
        desktopEnabled: boolean,
    ): InlineContentTypes[] => {
        return [
            inlineArticleAds({
                insertAfter: 3,
                noticePosition: 'below-center',
                hiddenUntilLoaded: false,
                mastHead: 'thenightly',
                slotID: 'mrec-one',
                size: desktopEnabled
                    ? 'leaderboardAbove768MrecBelow'
                    : 'below768Mrec',
                pageType: publicationAdPageType[article.kind],
                lazyLoadingDistance: 0,
            }),
        ]
    }

    const query = queryString.parse(location.search)
    const page = parsePageParam(query.page, MAX_LIVE_BLOG_PAGES)

    const link: PageMetaLinkDTO[] = []

    // use the canonical url of the article if provided, otherwise use the current page as the canonical
    const canonicalUrl =
        page > 1 && article._self === article.canonicalUrl
            ? getPaginatedCanonicalUrl(article._self, page)
            : article.canonicalUrl

    if (canonicalUrl) {
        link.push({ rel: 'canonical', href: canonicalUrl })
    }

    const isLatestNightlyOnEnabled = Boolean(
        featureState &&
            isFeatureEnabled(
                featureState,
                latestOnEdition.featureToggle as Features,
                false,
            ),
    )

    return {
        kind: 'page',
        heading: article.heading,
        pageMeta: {
            ...pageMeta,
            description: article.homepageTeaser,
            title: article.seoHead || undefined,
            meta: getNoIndexMeta(article.source, featureState),
            link: link,
        },
        socialMeta: {
            title: article.socialHead || article.homepageHead,
            description: article.socialTeaser || article.homepageTeaser,
        },
        hideHeading: true,
        noMetaTitleTemplate: false,
        pageType: publicationPageType,
        adTargeting,
        additionalPageProperties: {
            primaryTopic: article.topics.primary,
            secondaryTopics: article.topics.secondary,
            publicationKind: article.kind,
            // DPT-1375 extra Metadata for variant testing. Remove when finished
            requiredAccess: article.requiredAccess,
            layout: article.layoutView,
        },
        section,
        compositions: [
            layout.composition({
                type: 'scheme-overrider',
                props: {
                    override: sectionThemeOverrider(
                        'thenightly',
                        article.topics.primary.id as any as TheNightlySection,
                    ),
                },
                contentAreas: {
                    children: [
                        layout.nestedComposition({
                            type: 'luna',
                            props: {
                                hasAfterAside: false,
                                asideSide: 'right',
                            },
                            contentAreas: {
                                beforeAside: [
                                    ...(section !== 'default'
                                        ? [
                                              layout.nestedComposition({
                                                  type: 'box',
                                                  props: {
                                                      horizontalSpacingBreakpoint:
                                                          'sm',
                                                      horizontalSpacing: 'md',
                                                  },
                                                  contentAreas: {
                                                      main: [
                                                          layout.component({
                                                              type: 'thenightly-breadcrumb',
                                                              props: {
                                                                  topics: article.topics,
                                                                  nightlySectionNavItems:
                                                                      sectionNavItems(
                                                                          isLatestNightlyOnEnabled,
                                                                      ),
                                                                  subTopicNavItems:
                                                                      subTopicNavItems,
                                                              },
                                                          }),
                                                      ],
                                                  },
                                              }),
                                          ]
                                        : []),

                                    layout.component({
                                        type: 'article',
                                        props: {
                                            meta: {
                                                slug: article.slug,
                                                kind: 'article',
                                                section: section,
                                                inlinePublicationContent: [
                                                    ...standardInlineArticleAds(
                                                        standardInlineArticleAdsEnabled,
                                                    ),
                                                    {
                                                        kind: 'inline-tn-newsletter-banner',
                                                        insertAfter: 3,
                                                        content: [{}],
                                                    },
                                                    {
                                                        kind: 'inline-pollie-rater-banner',
                                                        insertAfter: 8,
                                                        toggle: enablePollyRaterBanner,
                                                    },
                                                    {
                                                        kind: 'inline-the-game-banner',
                                                        insertAfter: 10,
                                                        props: {
                                                            url: 'https://thegame.com.au?utm_source=the+game&utm_medium=article+banner&utm_campaign=afl+2025+national',
                                                            toggle: enableTheGameAflPromoDriver,
                                                            useImgV2: true,
                                                        },
                                                    },
                                                    ...(inApp
                                                        ? []
                                                        : [
                                                              {
                                                                  kind: 'inline-tn-register-account',
                                                                  insertAfter: 5,
                                                                  content: [{}],
                                                              } as InlineTNRegisterAccount,
                                                          ]),

                                                    ...extraInlineArticleAds,
                                                    ...(article.kind === 'event'
                                                        ? getInlineEventAds(
                                                              Product.TheNightly,
                                                          )
                                                        : []),
                                                ],
                                            },
                                            dataDefinitionArgs: {
                                                publicationId: article.id,
                                            },
                                            section,
                                            isVideoStickyEnabled: true,
                                            liveBlogOptions: {
                                                pageSize: LIVE_BLOG_PAGE_SIZE,
                                                maxPages: MAX_LIVE_BLOG_PAGES,
                                            },
                                        },
                                    }),
                                    layout.nestedComposition({
                                        type: 'box',
                                        props: {
                                            verticalSpacing: 'lg',
                                        },
                                        contentAreas: {
                                            main: [
                                                layout.component({
                                                    type: 'coral',
                                                    feature: 'comments',
                                                    props: {
                                                        storyID:
                                                            getCoralStoryId(
                                                                section,
                                                                config.publicHostname,
                                                                article.id,
                                                            ),
                                                        articleCommentsEnabled:
                                                            article.allowCommenting,
                                                        storyURL: `${article._self}`,
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                    ...getArticleFollowers(inApp),
                                ],
                                afterAside: [],
                                aside: [
                                    layout.nestedComposition({
                                        type: 'scheme-overrider',
                                        props: {
                                            override: sectionThemeOverrider(
                                                'thenightly',
                                                section,
                                            ),
                                        },
                                        contentAreas: {
                                            children: [
                                                layout.nestedComposition({
                                                    type: 'box',
                                                    props: {
                                                        hasTopSpacing: true,
                                                        verticalSpacing:
                                                            'gridGap',
                                                        nightlyArticleSidebar:
                                                            true,
                                                        horizontalSpacingBreakpoint:
                                                            'sm',
                                                        horizontalSpacing: 'lg',
                                                    },
                                                    contentAreas: {
                                                        main: [
                                                            relatedContent(
                                                                article.id,
                                                            ),
                                                            newsletterBanner(),
                                                            cardList(
                                                                {
                                                                    type: 'curation',
                                                                    name: 'top-stories',
                                                                    offset: 0,
                                                                    pageSize: 5,
                                                                },
                                                                'The Top 5',
                                                            ),
                                                        ],
                                                    },
                                                }),
                                            ],
                                        },
                                    }),
                                ],
                            },
                        }),
                        layout.nestedComposition({
                            type: 'box',
                            props: {
                                flex: {
                                    default: {
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    },
                                },
                            },
                            contentAreas: {
                                main: [
                                    layout.component({
                                        type: 'nightly-button',
                                        props: {
                                            variant: 'default',
                                            text: 'BACK TO TOP',
                                            color: 'primary',
                                            fill: 'text',
                                            layout: {
                                                alignSelf: 'center',
                                            },
                                            action: {
                                                type: 'button',
                                                onClick: () =>
                                                    window.scrollTo({ top: 0 }),
                                            },
                                            icon: {
                                                iconPosition: 'right',
                                                IconElement: (
                                                    <IconChevronUpTN />
                                                ),
                                            },
                                        },
                                    }),
                                ],
                            },
                        }),
                    ],
                },
            }),
        ],
    }
}

const getArticleFollowers = (inApp: boolean): CheckedComponentInformation[] => [
    layout.component({
        type: 'ad-unit',
        props: {
            noticePosition: 'below-center',
            hiddenUntilLoaded: false,
            padding: [0, 0, metrics.thenightly.margins.xxl, 0],
            slot: {
                id: 'leaderboard-two',
                size: 'leaderboard768Above',
            },
            adType: 'inline',
            lazyLoadingDistance: 0,
        },
    }),
    ...(inApp ? [] : [latestEditionSegment]),
]

const relatedContent = (id: string) =>
    layout.nestedComposition({
        type: 'box',
        props: {
            flex: {
                default: {
                    flexDirection: 'column',
                    flexGrow: 0.5,
                },
            },
        },
        contentAreas: {
            main: [
                layout.nestedComposition({
                    type: 'component-overrider',
                    props: {
                        override: () => theNightlySmallSectionHeaderOverride,
                    },
                    contentAreas: {
                        children: [
                            layout.component({
                                type: 'publication-related-content',
                                props: {
                                    title: 'More like this',
                                    displayMode: 'juliet',
                                    numberOfItems: 5,
                                    dataDefinitionArgs: {
                                        publicationId: id,
                                    },
                                },
                            }),
                            stickyAdUnit(),
                        ],
                    },
                }),
            ],
        },
    })

export const newsletterBanner = () =>
    layout.nestedComposition({
        type: 'box',
        props: {
            verticalSpacing: 'xxl',
            flex: {
                default: {
                    flexDirection: 'column',
                    flexGrow: 0.5,
                },
            },
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'tn-newsletter-signup',
                    props: {
                        text: `Sign up to The Nightly's newsletters.`,
                        subText:
                            'Get the first look at the digital newspaper, curated daily stories and breaking headlines delivered to your inbox.',
                        buttonLabel: 'Get the Newsletter',
                        mode: 'sidebar-article',
                    },
                }),
            ],
        },
    })

const stickyAdUnit = () =>
    layout.nestedComposition({
        type: 'box',
        props: {
            stickyOffset: 'calc(var(--stickyHeaderHeight) + 32px)',
            hasBackgroundFill: true,
        },
        contentAreas: {
            main: [
                layout.component({
                    type: 'ad-unit',
                    props: {
                        noticePosition: 'below-center',
                        slot: {
                            id: 'sidebar-one',
                            size: 'above1280MrecHalfPage',
                        },
                        adType: 'inline',
                        padding: [0, 0, 32, 0],
                    },
                }),
            ],
        },
    })
