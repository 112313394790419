import {
    AppState,
    AuthenticationState,
    retrieveCookie,
} from '@news-mono/web-common'
import React, { useCallback, useEffect, useState } from 'react'
import {
    addListener,
    removeListener,
    useAuthActions,
} from '@news-mono/component-library'
import {
    StickyBreachContainer,
    StyledTitle,
    StyledIconCross,
    StickyBreachWrapper,
    StyledText,
    StyledContentWrapper,
    Divider,
    StyledTextLink,
    StyledCloseButton,
    StyledTextButton,
} from './TNCreateAccountBreachModal.styled'
import {
    CREATE_ACCOUNT_COOKIE_NAME,
    CreateAccountBreachModalCookie,
    getCookieExpiresInDay,
    isPageTypeExcluded,
    storeCreateAccountModalCookie,
} from '../../warden'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { NightlyButton } from '../../../buttons/NightlyButton/NightlyButton'

export interface TNCreateAccountBreachModalProps {
    pageType: string
}

const shouldDisplayComponent = (
    isLoggedIn: boolean,
    pageType: string,
    cookie?: CreateAccountBreachModalCookie,
) => {
    // display the component if...
    return (
        !isLoggedIn && // not logged in, AND
        !isPageTypeExcluded(pageType) && // page type is not on excluded list, AND
        !cookie // cookie does not exist (expired)
    )
}

/**
 * Create Account Breach Sticky Container for The Nightly.
 */
export const TNCreateAccountBreachModal = ({
    pageType,
}: TNCreateAccountBreachModalProps) => {
    const [isDisplayed, setIsDisplayed] = useState<boolean>(pageType === 'test')
    const [isTimeExpired, setIsTimeExpired] = useState<boolean>(false)
    const [scrollPos, setScrollPos] = useState(0)

    const scrollTarget = 1200

    const { onLoginClick, onRegisterClick } = useAuthActions()
    const { isLoggedIn } = useSelector<AppState, AuthenticationState>(
        (state) => state.authentication,
    )
    const renditionType =
        useSelector((state: AppState) => state.render.renditionType) === 'app'
            ? 'app'
            : 'web'
    const location = useLocation()

    const checkComponentDisplay = useCallback(
        (pageType: string) => {
            const cookie = retrieveCookie<CreateAccountBreachModalCookie>(
                CREATE_ACCOUNT_COOKIE_NAME,
            )
            if (shouldDisplayComponent(isLoggedIn, pageType, cookie)) {
                setIsDisplayed(true)
                setIsTimeExpired(true)
            } else {
                setIsDisplayed(false)
            }
        },
        [isLoggedIn],
    )

    const handleClose = () => {
        setIsDisplayed(false)

        const cookie = retrieveCookie<CreateAccountBreachModalCookie>(
            CREATE_ACCOUNT_COOKIE_NAME,
        )

        const expires = getCookieExpiresInDay(1)
        if (!cookie) {
            storeCreateAccountModalCookie(false, expires)
        }
    }

    const handleScroll = () => {
        setScrollPos(window.scrollY)
    }

    // run once to hook the window scroll
    useEffect(() => {
        addListener('scroll', handleScroll)
        return () => {
            removeListener('scroll', handleScroll)
        }
    }, [])

    // scrolling will trigger display check
    useEffect(() => {
        if (window.scrollY >= scrollTarget) checkComponentDisplay(pageType)
    }, [checkComponentDisplay, pageType, scrollPos])

    // url change will trigger display check
    useEffect(() => {
        if (isPageTypeExcluded(pageType)) {
            setIsDisplayed(false)
        } else {
            if (isTimeExpired) {
                checkComponentDisplay(pageType)
            } else {
                const timeCheck = setTimeout(() => {
                    checkComponentDisplay(pageType)
                }, 10000)
                return () => clearTimeout(timeCheck)
            }
        }
    }, [checkComponentDisplay, isTimeExpired, location, pageType])

    return isDisplayed ? (
        <StickyBreachContainer>
            <StickyBreachWrapper>
                <StyledCloseButton onClick={handleClose}>
                    <StyledIconCross />
                </StyledCloseButton>
                <StyledTitle>Join The Nightly for full access.</StyledTitle>
                <StyledContentWrapper>
                    <StyledText padBottom={true}>
                        Australia’s best journalism for FREE, including our
                        weeknight digital edition, puzzles, newsletters and
                        comments.
                    </StyledText>
                    <NightlyButton
                        variant="default"
                        color="emphasis"
                        fill="filled"
                        action={{
                            type: 'button',
                            onClick: () =>
                                onRegisterClick('footer-banner', renditionType),
                        }}
                        text={'CREATE FREE ACCOUNT'}
                    />
                    <StyledTextButton onClick={handleClose}>
                        Skip for now
                    </StyledTextButton>
                    <Divider />
                    <StyledText>
                        Already have an account?{' '}
                        <StyledTextLink
                            onClick={() => onLoginClick('footer-banner')}
                        >
                            Log in here.
                        </StyledTextLink>
                    </StyledText>
                </StyledContentWrapper>
            </StickyBreachWrapper>
        </StickyBreachContainer>
    ) : null
}
